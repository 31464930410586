import React, { Component } from 'react';
import { bool, func, number, object, string } from 'prop-types';
import clsx from 'clsx';
import withSizes from 'react-sizes';
import Spinner from '../Spinner';
import { customFabric as fabric } from '../../components/FabricComponents';
import ButtonReplay from '../ButtonReplay';
import InstagramMode from '../InstagramMode';
import {
    getAnimatedProp,
    getAppearanceAnimationsParams,
    getDisappearanceAnimationsParams,
} from '../../utils/animations';
import { convertToSeconds, createElement } from '../../utils/common';
import {
    findCanvasItem,
    setCanvasObjectParams,
    maxDuration,
    maxDelay,
    maxDurationOnly,
    calculateZoomDuration,
    maxAppearanceDelay,
} from '../../utils/canvas';
import { SLIDE_ASPECT_RATIO } from '../../constants/sizes';
import {
    CANVAS_TYPE_OBJECTS,
    CANVAS_SCALE_TYPES,
    CLIPPATH_CREATE_OPTIONS,
    IMAGE_CREATE_OPTIONS,
    VIDEOS_WRAPPER_ID,
} from '../../constants/canvas';
import {
    TRANSITIONS_ANIMATION_EASING,
    APPEARANCE_TRANSITIONS_TYPE,
    DISAPPEARANCE_TRANSITIONS_TYPE,
} from '../../constants/transitions';
import {
    BUILD_FULL_FILE_PATH,
    SCALE_PLACEHOLDER_URL_SHORT,
} from '../../constants/slides';
import classes from './CanvasPreview.module.scss';

class CanvasPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key: 0,
            canvas: null,
            isPlaying: true,
            isLoading: true,
            videoElements: [],
        };
        this.mounted = false;
        this.totalDuration = 0;
        this.abortAnimation = this.abortAnimation.bind(this);
        this.renderCanvas = this.renderCanvas.bind(this);
        this.prepareAnimations = this.prepareAnimations.bind(this);
        this.animateCanvas = this.animateCanvas.bind(this);
        this.animateAll = this.animateAll.bind(this);
        this.updateZoom = this.updateZoom.bind(this);
        this.initZoom = this.initZoom.bind(this);
    }

    abortAnimation() {
        return !this.mounted;
    }

    async prepareAnimations(canvas) {
        const { slideDuration } = this.props;

        const objects = canvas.getObjects();
        for (let index = 0, len = objects.length; index < len; index++) {
            const o = objects[index];
            if (canvas && o?.animation) {
                const {
                    appearance,
                    playAppearanceAnimations,
                    disappearance,
                    playDisappearanceAnimations,
                } = o.animation;

                const maxAppearanceDuration =
                    Array.isArray(appearance) && playAppearanceAnimations
                        ? appearance.reduce(maxDuration, 0)
                        : 0;
                const maxDisappearanceDuration =
                    Array.isArray(disappearance) && playDisappearanceAnimations
                        ? disappearance.reduce(maxDuration, 0)
                        : 0;

                if (
                    slideDuration == null &&
                    +maxAppearanceDuration + maxDisappearanceDuration > this.totalDuration
                ) {
                    this.totalDuration =
                        +maxAppearanceDuration + maxDisappearanceDuration + 250; // additional 250 ms due to setTimeout delays
                }
            }
            const isMedia = CANVAS_SCALE_TYPES.includes(o.type);
            if (isMedia) {
                const isGroup = o.type === CANVAS_TYPE_OBJECTS.group;
                const isVideo = o.type === CANVAS_TYPE_OBJECTS.videoImage;
                const isText = o.type === CANVAS_TYPE_OBJECTS.animatedTextbox;
                const { width, height, left, top, angle } = o;
                if (isText && o.zoomIn) {
                    setCanvasObjectParams(o, { angle: 0 });
                    o.setCoords();
                    o.cloneAsImage(
                        img => {
                            setCanvasObjectParams(img, {
                                angle,
                                left,
                                top,
                                zoomIn: o.zoomIn,
                                zoomInValue: o.zoomInValue,
                                typingAnimation: o.typingAnimation,
                                typingAnimationInterval: o.typingAnimationInterval,
                                animation: o.animation,
                                originX: 'center',
                                originY: 'center',
                                scaleX: o.scaleX / o.zoomInValue,
                                scaleY: o.scaleY / o.zoomInValue,
                                objectCaching: false,
                                strokeUniform: true,
                            });
                            o.setCoords();
                            canvas.insertAt(img, index, true);
                        },
                        {
                            format: 'png',
                            width,
                            height,
                            multiplier: o.zoomInValue,
                        },
                    );
                } else if (isGroup) {
                    const imgElement = await createElement({
                        type: 'img',
                        url: BUILD_FULL_FILE_PATH(SCALE_PLACEHOLDER_URL_SHORT),
                        width: 720,
                        height: 1280,
                    });
                    const { x, y } = o.getCenterPoint();
                    o.set({ originX: 'center', originY: 'center' });
                    o.setCoords();
                    const { x: offsetX, y: offsetY } = o.getCenterPoint();
                    o.setCoords();
                    o.set({
                        left: left - (offsetX - x),
                        top: top - (offsetY - y),
                    });
                    o.setCoords();
                    const options = CLIPPATH_CREATE_OPTIONS(o);
                    const mediaElement = new fabric.AnimatedImage(imgElement, {
                        ...IMAGE_CREATE_OPTIONS(),
                        zoomIn: o.zoomIn,
                        zoomInValue: o.zoomInValue,
                        animation: o.animation,
                        src: BUILD_FULL_FILE_PATH(SCALE_PLACEHOLDER_URL_SHORT),
                        originX: 'center',
                        originY: 'center',
                        left: o.left,
                        top: o.top,
                        width: 720,
                        height: 1280,
                        clipPath: new fabric.AnimatedRect({
                            ...options,
                            fill: null,
                            absolutePositioned: true,
                        }),
                    });
                    canvas.insertAt(mediaElement, index, true);
                } else if (o.zoomIn) {
                    const { left, top } = o;
                    if (!o.clipPath) {
                        // Get center before
                        const { x, y } = o.getCenterPoint();
                        const scaleObject = {
                            evented: false,
                            selectable: false,
                            absolutePositioned: false,
                            originX: 'center',
                            originY: 'center',
                        };
                        setCanvasObjectParams(o, scaleObject);
                        o.setCoords();
                        // Get center after origin change
                        const { x: offsetX, y: offsetY } = o.getCenterPoint();
                        const options = CLIPPATH_CREATE_OPTIONS(o);
                        const clipPath = new fabric.AnimatedRect({
                            ...options,
                            left: options.left + (x - offsetX),
                            top: options.top + (y - offsetY),
                            fill: null,
                            absolutePositioned: true,
                        });
                        setCanvasObjectParams(o, {
                            left: left + (x - offsetX),
                            top: top + (y - offsetY),
                            clipPath,
                        });
                        o.setCoords();
                    } else {
                        // Get center before
                        if (o.originX === 'left' || o.originY === 'top') {
                            const { x, y } = o.getCenterPoint();
                            setCanvasObjectParams(o, {
                                evented: false,
                                selectable: false,
                                originX: 'center',
                                originY: 'center',
                                absolutePositioned: isVideo,
                            });
                            o.setCoords();
                            // Get center after origin change
                            const { x: offsetX, y: offsetY } = o.getCenterPoint();
                            setCanvasObjectParams(o, {
                                left: left + (x - offsetX),
                                top: top + (y - offsetY),
                            });
                            o.setCoords();
                        } else {
                            setCanvasObjectParams(o, {
                                evented: false,
                                selectable: false,
                                absolutePositioned: isVideo,
                            });
                        }
                    }
                }
            }
        }
    }

    animateCanvas(canvas) {
        const { projectId } = this.props;
        canvas.forEachObject(o => {
            // Typewriter animation
            const textBox = o.type === CANVAS_TYPE_OBJECTS.animatedTextbox;
            if (textBox && o.typingAnimation) {
                let maxAppearanceDelayDuration = 0;
                let maxDisappearDelayDuration = 0;
                let maxAppearDuration = 0;
                let hasAppear = false;
                if (o?.animation) {
                    const {
                        appearance,
                        playAppearanceAnimations,
                        disappearance,
                        playDisappearanceAnimations,
                    } = o.animation;
                    maxAppearanceDelayDuration =
                        Array.isArray(appearance) && playAppearanceAnimations
                            ? appearance.reduce(maxDelay, 0)
                            : 0;
                    maxAppearDuration =
                        Array.isArray(appearance) && playAppearanceAnimations
                            ? appearance.reduce(maxDurationOnly, 0)
                            : 0;
                    maxDisappearDelayDuration =
                        Array.isArray(disappearance) && playDisappearanceAnimations
                            ? disappearance.reduce(maxDelay, 0)
                            : 0;
                    hasAppear =
                        Array.isArray(appearance) &&
                        playAppearanceAnimations &&
                        !!appearance.find(
                            a =>
                                a.typeTransition === APPEARANCE_TRANSITIONS_TYPE[1].value,
                        );
                }
                let onStage = maxDisappearDelayDuration
                    ? maxAppearDuration + maxDisappearDelayDuration
                    : this.totalDuration - maxAppearanceDelayDuration;
                if (hasAppear) {
                    onStage = onStage - maxAppearDuration;
                }
                const time = Math.floor(onStage / o.text.length);
                const typingInterval = projectId
                    ? Math.min(time, o.typingAnimationInterval)
                    : o.typingAnimationInterval;
                o.setCoords();
                o.setSelectionStyles({ fill: 'transparent' }, 0, o.text.length);
                o.set({
                    verticalAlign: 'top',
                    originY: 'top',
                    top: o.top * o.scaleY - o.height * 0.5 * o.scaleY,
                });
                o.setCoords();
                let i = 0;
                setTimeout(() => {
                    let interval = setInterval(() => {
                        o.setSelectionStyles({ fill: o.fill }, 0, i);
                        i++;
                        if (!this.mounted) {
                            clearInterval(interval);
                        }
                    }, typingInterval);
                }, maxAppearanceDelayDuration);
            }

            if (canvas && o?.animation) {
                const {
                    appearance,
                    playAppearanceAnimations,
                    disappearance,
                    playDisappearanceAnimations,
                } = o.animation;

                const appearancePromises = [];
                if (Array.isArray(appearance) && playAppearanceAnimations) {
                    appearance.forEach(appearanceAnimation => {
                        const {
                            typeTransition,
                            options: {
                                // from,
                                duration,
                                delay,
                                easing = TRANSITIONS_ANIMATION_EASING[0].value,
                            },
                        } = appearanceAnimation;
                        const animatedProp = getAnimatedProp(typeTransition);
                        // Get recalculated params depending to object changes
                        const { value, params } = getAppearanceAnimationsParams(
                            {
                                ...appearanceAnimation.options,
                                ...appearanceAnimation,
                            },
                            canvas,
                            o,
                            o.getScaledWidth(),
                            o.getScaledHeight(),
                        );

                        const clipPathProp =
                            o.clipPath && o.clipPath[animatedProp]
                                ? o.clipPath[animatedProp]
                                : 0;
                        const objectProp = o[animatedProp];
                        const diff = clipPathProp - objectProp;
                        if (typeTransition !== APPEARANCE_TRANSITIONS_TYPE[0].value) {
                            o.set({ [animatedProp]: params[animatedProp] });
                            o.clipPath &&
                                o.clipPath.absolutePositioned &&
                                o.clipPath.set({
                                    [animatedProp]: params[animatedProp] + diff,
                                });
                            appearancePromises.push(
                                new Promise(resolve => {
                                    setTimeout(() => {
                                        if (
                                            o.clipPath &&
                                            o.clipPath.absolutePositioned &&
                                            [
                                                CANVAS_TYPE_OBJECTS.videoImage,
                                                CANVAS_TYPE_OBJECTS.animatedImage,
                                            ].includes(o.type)
                                        ) {
                                            o.clipPath.animate(
                                                animatedProp,
                                                value + diff,
                                                {
                                                    from: params[animatedProp] + diff,
                                                    abort: this.abortAnimation,
                                                    duration:
                                                        typeTransition ===
                                                        APPEARANCE_TRANSITIONS_TYPE[1]
                                                            .value
                                                            ? 0
                                                            : duration,
                                                    easing: fabric.util.ease[easing],
                                                    onChange: () => {
                                                        setCanvasObjectParams(o, {
                                                            dirty: true,
                                                        });
                                                    },
                                                },
                                            );
                                        }
                                        o.animate(animatedProp, value, {
                                            from: params[animatedProp],
                                            duration:
                                                typeTransition ===
                                                APPEARANCE_TRANSITIONS_TYPE[1].value
                                                    ? 0
                                                    : duration,
                                            abort: this.abortAnimation,
                                            easing: fabric.util.ease[easing],
                                            onChange: () => {
                                                setCanvasObjectParams(o, {
                                                    dirty: true,
                                                });
                                            },
                                            onComplete: () => resolve(),
                                        });
                                    }, delay);
                                }),
                            );
                        }
                    });
                }
                Promise.all(appearancePromises).then(() => {
                    if (Array.isArray(disappearance) && playDisappearanceAnimations) {
                        disappearance.forEach(disappearanceAnimation => {
                            const {
                                typeTransition,
                                options: {
                                    duration,
                                    delay,
                                    easing = TRANSITIONS_ANIMATION_EASING[0].value,
                                },
                            } = disappearanceAnimation;
                            const animatedProp = getAnimatedProp(typeTransition);
                            const {
                                params: {
                                    animation: { value },
                                },
                            } = getDisappearanceAnimationsParams(
                                {
                                    ...disappearanceAnimation.options,
                                    ...disappearanceAnimation,
                                },
                                canvas,
                                o,
                                o.getScaledWidth(),
                                o.getScaledHeight(),
                            );
                            const clipPathProp =
                                o.clipPath && o.clipPath[animatedProp]
                                    ? o.clipPath[animatedProp]
                                    : 0;
                            const objectProp = o[animatedProp];
                            const diff = clipPathProp - objectProp;
                            if (
                                typeTransition !== DISAPPEARANCE_TRANSITIONS_TYPE[0].value
                            ) {
                                setTimeout(() => {
                                    if (
                                        o.clipPath &&
                                        o.clipPath.absolutePositioned &&
                                        [
                                            CANVAS_TYPE_OBJECTS.videoImage,
                                            CANVAS_TYPE_OBJECTS.animatedImage,
                                        ].includes(o.type)
                                    ) {
                                        o.clipPath.animate(animatedProp, value + diff, {
                                            from: o.clipPath[animatedProp],
                                            abort: this.abortAnimation,
                                            duration:
                                                typeTransition ===
                                                DISAPPEARANCE_TRANSITIONS_TYPE[1].value
                                                    ? 0
                                                    : duration,
                                            easing: fabric.util.ease[easing],
                                            onChange: () => {
                                                setCanvasObjectParams(o, {
                                                    dirty: true,
                                                });
                                            },
                                        });
                                    }
                                    o.animate(animatedProp, value, {
                                        duration:
                                            typeTransition ===
                                            DISAPPEARANCE_TRANSITIONS_TYPE[1].value
                                                ? 0
                                                : duration,
                                        from: o[animatedProp],
                                        abort: this.abortAnimation,
                                        easing: fabric.util.ease[easing],
                                        onChange: () => {
                                            setCanvasObjectParams(o, {
                                                dirty: true,
                                            });
                                        },
                                    });
                                }, delay);
                            }
                        });
                    }
                });
            }

            // Zoom animation
            const isGroup = o.src === BUILD_FULL_FILE_PATH(SCALE_PLACEHOLDER_URL_SHORT);
            if (o.zoomIn) {
                const maxAppearanceDelayTime = maxAppearanceDelay(o.animation);
                const zoomDuration = calculateZoomDuration(
                    this.totalDuration,
                    maxAppearanceDelayTime,
                    o.animation,
                );

                setTimeout(() => {
                    const ratio = o.width / o.height;
                    const w = isGroup ? o.getScaledWidth() : o.width;
                    o.animate('width', w * o.zoomInValue, {
                        from: w,
                        duration: zoomDuration,
                        abort: this.abortAnimation,
                        onChange: val => {
                            setCanvasObjectParams(o, {
                                height: val / ratio,
                                dirty: true,
                            });
                        },
                        onComplete: () => {
                            setCanvasObjectParams(o, {
                                width: w,
                                height: w / ratio,
                            });
                        },
                    });
                }, maxAppearanceDelayTime);
            }
        });
        // this.totalDuration += 250; // due setTimeout delays
        const { isLoading } = this.state;
        // Force to disable spinner
        if (isLoading) {
            this.setState({ isLoading: false });
        }
        // Reset progress
        this.start = null;
        // Start progress
        fabric.util.requestAnimFrame(this.animateAll);
    }

    animateAll(frame) {
        if (!this.mounted) {
            return;
        }
        const { counter, index } = this.props;
        if (counter !== index) {
            return;
        }
        if (!this.start) {
            this.start = frame;
        }
        const { canvas, isLoading } = this.state;
        // TODO add check if canvas is static
        canvas.renderAll();
        // use -1 to wait until during canvas load we will reset this.start (this.start = null)
        // to proceed progress calculations
        const progress = this.start === -1 ? 0 : frame - this.start;
        const res = this.start === -1 ? 0 : (progress / this.totalDuration) * 100;
        const playProgress = Math.min(res, 100);
        const { updateProgress, playNextSlide } = this.props;
        // Update progress inside parent
        if (this.mounted && updateProgress) {
            updateProgress(isLoading ? 0 : playProgress);
        }
        // Switch slides
        if (this.mounted && playProgress === 100) {
            this.mounted = false;
            this.start = -1;
            this.totalDuration = 0;
            if (counter === index && playNextSlide) {
                playNextSlide();
            } else {
                this.mounted = false;
            }
        }

        fabric.util.requestAnimFrame(this.animateAll);
    }

    initZoom(zoomedCanvas) {
        const { width } = this.props;
        if (
            width !== zoomedCanvas.canvasInitialWidth &&
            width !== zoomedCanvas.canvasCurrentWidth
        ) {
            const scale = width / zoomedCanvas.canvasInitialWidth;
            let zoom = zoomedCanvas.getZoom();
            zoom *= scale;
            if (!Number.isNaN(Number(zoom)) && Number(zoom) > 0) {
                zoomedCanvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
            }
        }
    }

    async renderCanvas(canvas, canvasData) {
        try {
            const { slideDuration } = this.props;
            // Needed for clipPath proper deserialization
            // BE AWARE OF FACT THAT THIS COULD OVERRIDE "canvasData" OBJECT !!
            // ALWAYS PROVIDE FRESH NEW "canvasData" OBJECT INSTANCE
            // TO AVOID DESERIALIZATION ERRORS WITH CACHED CANVAS
            canvasData.objects.forEach(obj => {
                obj.evented = false;
                obj.selectable = false;
                if (obj.clipPath) {
                    fabric.util.enlivenObjects([obj.clipPath], ([arg]) => {
                        obj.clipPath = arg;
                    });
                }
            });
            const canvasLoaded = async () => {
                const objs = canvasData.objects;
                this.initZoom(canvas);
                // handle restoring video
                const videos = objs.filter(o =>
                    Object.prototype.hasOwnProperty.call(o, 'videoSrc'),
                );
                if (videos && videos.length) {
                    for await (const vid of videos) {
                        // We need to use custom dimensions property here because of the
                        // how Fabric.js handles images (that are used for video preview) and real videos
                        const { videoSrc: url, width, height, dimensions } = vid;
                        // Check video duration
                        if (slideDuration == null) {
                            const duration = convertToSeconds(vid?.videoDuration);
                            if (duration * 1000 > this.totalDuration) {
                                this.totalDuration = duration * 1000;
                            }
                        }
                        try {
                            const { object, index } = findCanvasItem(
                                vid.objectId,
                                canvas,
                            );
                            if (index >= 0) {
                                // Use previously cached video using custom "url" property
                                // apps/frontend/src/app/utils/common.js:89
                                const videoElements = Array.from(
                                    document.querySelectorAll(
                                        `video[data-url="${url}?${vid.objectId}"]`,
                                    ),
                                );
                                let videoEl = videoElements[0];

                                if (!videoEl) {
                                    // Create, cache and append video file to wrapper div
                                    videoEl = await createElement({
                                        url,
                                        type: 'video',
                                        id: vid.objectId,
                                        ...dimensions,
                                    });
                                    const videosWrapper = document.getElementById(
                                        VIDEOS_WRAPPER_ID,
                                    );
                                    if (videosWrapper) {
                                        videosWrapper.appendChild(videoEl);
                                    }
                                } else {
                                    // Stop and rewind to the start cached video
                                    if (videoEl.currentTime > 0) {
                                        videoEl.pause();
                                        videoEl.currentTime = 0;
                                    }
                                }
                                this.setState(prevState => {
                                    const { videoElements } = prevState;
                                    videoElements.push(videoEl);
                                    return { videoElements };
                                });
                                // Since image and video has different sizes
                                // so we need to scale object to width of video (not Image.naturalWidth)
                                const newScaleX = (width / dimensions.width) * vid.scaleX;
                                const newScaleY =
                                    (height / dimensions.height) * vid.scaleY;
                                object.setElement(videoEl, {
                                    minScaleLimit: Math.min(newScaleY, newScaleX),
                                    scaleX: newScaleX,
                                    scaleY: newScaleY,
                                    // This needed in case of object clipPath
                                    // https://stackoverflow.com/a/59794706/13168213
                                    objectCaching: true,
                                    statefullCache: true,
                                    cacheProperties: ['videoTime'],
                                    // Hide icon and duration text at video
                                    isPreview: true,
                                    ...dimensions,
                                });
                                object.setCoords();
                                object.clipPath && object.clipPath.setCoords();
                                canvas.renderAll();
                                if (
                                    object.clipPath &&
                                    !object.clipPath.absolutePositioned
                                ) {
                                    const scaleDiffX = dimensions.width / width;
                                    const scaleDiffY = dimensions.height / height;
                                    object.clipPath.set({
                                        scaleX: object.clipPath.scaleX * scaleDiffX,
                                        scaleY: object.clipPath.scaleY * scaleDiffY,
                                        left: object.clipPath.left * scaleDiffX,
                                        top: object.clipPath.top * scaleDiffY,
                                    });
                                }
                                const isPlaying =
                                    videoEl.currentTime > 0 &&
                                    !videoEl.paused &&
                                    !videoEl.ended &&
                                    videoEl.readyState > 2;
                                if (!isPlaying) {
                                    try {
                                        await videoEl.play();
                                    } catch (e) {
                                        console.info(e?.message);
                                    }
                                }
                                // Needed for videos with clipPath
                                // eslint-disable-next-line no-loop-func
                                const render = () => {
                                    const { isPlaying } = this.state;
                                    if (isPlaying) {
                                        canvas.renderAll();
                                        object.videoTime = videoEl.currentTime;
                                        fabric.util.requestAnimFrame(render);
                                    } else {
                                        videoEl.pause();
                                    }
                                };
                                if (
                                    (object.clipPath && !object.zoomIn) ||
                                    (object.clipPath && objs.length === 1)
                                ) {
                                    fabric.util.requestAnimFrame(render);
                                }
                            }
                        } catch (e) {
                            canvas.renderAll();
                            console.info(e);
                        }
                    }
                }

                await this.prepareAnimations(canvas);
                if (videos && videos.length) {
                    // TODO Since we use duration in seconds it's not precise
                    // so we need to add this 0.5s to "balance" to handle stop of preview
                    // if video duration is non-integer
                    this.totalDuration += 500;
                }
                // If slideDuration provided explicitly - we use it
                // instead of calculating it based on longest duration of canvas objects
                if (slideDuration) {
                    this.totalDuration = slideDuration * 1000;
                }
                if (this.mounted) {
                    this.setState({ canvas }, () => {
                        this.start = null;
                        this.animateCanvas(canvas);
                    });
                }
            };
            canvas = canvas.loadFromJSON(canvasData, canvasLoaded);
        } catch (e) {
            console.info('Loading state [ERROR]:', e);
            this.setState({ canvas });
        }
    }

    componentDidMount() {
        this.mounted = true;
        const { canvasData, standalone } = this.props;
        const prevState = JSON.parse(JSON.stringify(canvasData));
        const canvas = new fabric.StaticCanvas(this.c, {
            backgroundColor: prevState.background,
            preserveObjectStacking: true,
        });
        canvas.set('type', 'Canvas');
        if (standalone) {
            // Create video wrapper inside DOM
            const videosWrapper = document.createElement('div');
            videosWrapper.id = VIDEOS_WRAPPER_ID;
            videosWrapper.setAttribute('class', 'hidden');
            document.body.appendChild(videosWrapper);
        }
        this.renderCanvas(canvas, prevState);
    }

    updateZoom() {
        const { canvas } = this.state;
        const { width, height } = this.props;
        if (canvas) {
            let w = width;
            let h = height;
            const ratio = canvas.getWidth() / canvas.getHeight();
            if (width / height > ratio) {
                w = height * ratio;
            } else {
                h = width / ratio;
            }
            const scale = w / canvas.getWidth();
            let zoom = canvas.getZoom();
            zoom *= scale;
            canvas.setDimensions({ width: w, height: h });
            if (!Number.isNaN(Number(zoom)) && Number(zoom) > 0) {
                canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
            }
            canvas.renderAll();
        }
    }

    componentDidUpdate(prevProps) {
        const { canvasData, isPlayingStory, width, height, counter } = this.props;
        // scale and rerender on resize
        if (
            (width && prevProps.width !== width) ||
            (height && prevProps.height !== height)
        ) {
            this.updateZoom();
        }

        if (
            prevProps.counter !== counter ||
            (canvasData && prevProps.canvasData !== canvasData)
        ) {
            const { canvas } = this.state;
            if (canvas) {
                const data = JSON.parse(JSON.stringify(canvasData));
                this.setState({ isLoading: true }, async () => {
                    this.totalDuration = 0;
                    this.mounted = true;
                    this.start = -1;
                    await this.renderCanvas(canvas, data);
                });
            }
        }
        // End preview from parent
        if (isPlayingStory !== null && prevProps.isPlayingStory !== isPlayingStory) {
            this.mounted = false;
            this.setState({ isPlaying: isPlayingStory });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
        const { standalone } = this.props;
        if (standalone) {
            // Remove cached videos from DOM
            const videosWrapper = document.getElementById(VIDEOS_WRAPPER_ID);
            if (videosWrapper && videosWrapper.parentNode) {
                videosWrapper.parentNode.removeChild(videosWrapper);
            }
        }
        this.setState({ canvas: null });
    }

    render() {
        const { isPlaying, isLoading, videoElements } = this.state;
        const {
            width,
            height,
            restart,
            index,
            counter,
            playNextSlide,
            zIndex,
            standalone,
            transitionDuration,
            nextTransitionName,
            selfTransitionName,
            project,
            projectId,
            instagramMode,
            template,
            templateId,
            slideId,
        } = this.props;
        let className = '';

        if (
            nextTransitionName &&
            nextTransitionName !== selfTransitionName &&
            counter > 0
        ) {
            className = classes['appear-' + selfTransitionName];
        } else if (counter > 0) {
            className = classes['appear-' + (nextTransitionName || selfTransitionName)];
        }

        const playNext = () => {
            if (
                !standalone &&
                playNextSlide &&
                videoElements.length > 0 &&
                transitionDuration &&
                nextTransitionName !== 'none'
            ) {
                videoElements.forEach(videoEl => {
                    videoEl.pause();
                    videoEl.currentTime = this.totalDuration * 0.001;
                });
                setTimeout(() => {
                    playNextSlide();
                }, 750);
            } else if (!standalone && playNextSlide) {
                playNextSlide();
            }
        };

        return (
            <div
                id={slideId}
                className={clsx(
                    standalone ? classes.standalone : classes.slides,
                    index === counter && classes.visible,
                    index === counter && className,
                )}
                style={{
                    width: `${width}px`,
                    height: `${height}px`,
                    transitionDuration: transitionDuration + 'ms',
                    animationDuration: transitionDuration + 'ms',
                    zIndex,
                }}
            >
                {standalone && instagramMode && (
                    <InstagramMode
                        object={(projectId && project) || (templateId && template)}
                    />
                )}
                {isLoading && <Spinner loading={isLoading} overlay />}
                {!isPlaying && <ButtonReplay handleClick={() => restart(0)} />}
                <canvas
                    ref={c => {
                        this.c = c;
                    }}
                    width={width + 'px'}
                    height={height + 'px'}
                    onClick={index === counter ? playNext : undefined}
                    className={clsx(classes.canvas, isLoading && classes.isLoading)}
                />
            </div>
        );
    }
}

const mapSizesToProps = ({ height }) => ({
    height: Math.floor(height - (72 + 69 + 32)),
    width: Math.floor((height - (72 + 69 + 32)) * SLIDE_ASPECT_RATIO),
});

CanvasPreview.propTypes = {
    templateId: string,
    projectId: string,
    instagramMode: bool,
    canvasData: object,
    isPlayingStory: bool,
    playNextSlide: func, // When click on the canvas switch to next slide
    restart: func, // When click on the replay
    slideDuration: number, // When click on the replay
    counter: number,
    standalone: bool,
    slideId: string,
};

export default withSizes(mapSizesToProps)(CanvasPreview);
