import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { bool, func, object } from 'prop-types';
import ColorPicker from '../MaterialColorPicker';
import Collapse from '../Collapse';
import { WithCanvasContext } from '../../utils/context';
import RefContext from '../../utils/refs-context';
import { saveCanvasState } from '../../utils/canvas';
import {
    DEFAULT_OBJECTS_MAP,
    CANVAS_ALLOWED_COLORED_OBJECTS,
} from '../../constants/canvas';
import { isCanvas, isLine, isRect } from '../../utils/canvas';
import { getTransparencyFromColor } from '../../utils/common';
import { locale } from '../../constants/locales';
import {
    DEFAULT_BG_COLOR,
    DEFAULT_RECT_COLOR,
    DEFAULT_TRANSPARENCY,
} from '../../constants/variables';
import { getTypeColorPicker } from '../../constants/color-pickers';

function ColorPickerWrapper({
    canvas,
    activeObject,
    saveCanvasToHistory,
    canvasIndex,
    disabled,
    showEyedropper,
    toggleEyeDropper,
}) {
    const { refs } = useContext(RefContext);
    const { eyedropperOn = false } = refs;
    const [expanded, setExpanded] = useState(false);
    const [color, setColor] = useState(DEFAULT_RECT_COLOR);
    const [transparency, setTransparency] = useState(DEFAULT_TRANSPARENCY);
    const canvasIndexRef = useRef(canvasIndex);

    const updateProp = useCallback(() => {
        const { enableColor, savedColor } = activeObject;
        if (savedColor && enableColor == null) {
            activeObject.set({ enableColor: true });
            // TODO Find a way to not have conccurency with text creation
            // saveCanvasState(canvas, saveCanvasToHistory, canvasIndexRef?.current, false);
        }
    }, [activeObject]);

    useEffect(() => {
        if (canvasIndexRef) {
            canvasIndexRef.current = canvasIndex;
        }
    }, [canvasIndex]);

    // Handle checkbox and color on undo/redo
    useEffect(() => {
        if (activeObject) {
            const {
                enableColor,
                savedColor,
                stroke,
                fill,
                backgroundColor,
            } = activeObject;
            // Support of 'enableColor' prop for previously created templates
            updateProp();
            // Get checked from saved in history active object
            if (expanded !== Boolean(enableColor)) {
                setExpanded(Boolean(enableColor));
            }
            if (isCanvas(activeObject)) {
                const color = savedColor ? savedColor : backgroundColor;
                setColor(color);
                setTransparency(getTransparencyFromColor(color));
            } else if (isLine(activeObject)) {
                const color = savedColor ? savedColor : stroke;
                setColor(color);
                setTransparency(getTransparencyFromColor(color));
            } else {
                const color = savedColor ? savedColor : fill;
                setColor(color);
                setTransparency(getTransparencyFromColor(color));
            }
        }
    }, [expanded, updateProp, setColor, setTransparency, activeObject, canvasIndex]);

    const getElement = () => activeObject || canvas.getActiveObject();

    const getDefaultColor = type =>
        (type && DEFAULT_OBJECTS_MAP[type].color) || DEFAULT_BG_COLOR;

    const setProperties = async newColor => {
        try {
            const el = getElement();
            const defaultColor = eyedropperOn ? el.savedColor : getDefaultColor(el.type);
            if (isCanvas(el)) {
                el.set({ backgroundColor: newColor });
            } else if (isLine(el)) {
                el.set({ stroke: newColor });
            } else if (isRect(el)) {
                el.set({
                    backgroundColor: newColor,
                    fill: newColor,
                });
            } else {
                el.set({ fill: newColor });
            }

            // Update savedColor with new only if it's not default color for element
            if (newColor != null && newColor !== defaultColor) {
                el.set('savedColor', newColor);
            }
            canvas.renderAll();
            if (canvasIndex > -1) {
                await saveCanvasState(
                    canvas,
                    saveCanvasToHistory,
                    canvasIndexRef?.current,
                );
            }
            canvas.renderAll();
        } catch (e) {
            console.info('Cannot find active element', e);
        }
    };

    const handleChangeComplete = async newValues => {
        if (typeof newValues === 'string') {
            await setProperties(newValues);
        } else {
            const { value: newColor } = newValues;
            await setProperties(newColor);
        }
    };

    const handleColorChange = async newValues => {
        if (typeof newValues === 'string') {
            setColor(newValues);
        } else {
            const { value: newColor } = newValues;
            setColor(newColor);
        }
    };

    const handleCheckbox = async event => {
        const { checked } = event.target;
        setExpanded(checked);
        const el = getElement();
        el.set('enableColor', checked);
        // Update "savedColor" once only here on first user checkbox click
        if (el.savedColor == null) {
            el.set('savedColor', getDefaultColor(el.type));
        }
        const savedColor =
            checked && el.savedColor ? el.savedColor : getDefaultColor(el.type);
        await setProperties(savedColor, transparency);
    };

    return (
        activeObject &&
        CANVAS_ALLOWED_COLORED_OBJECTS.includes(activeObject.type) && (
            <div className="boxBorder">
                <Collapse
                    expanded={expanded}
                    checkbox
                    checkboxColor="secondary"
                    label={
                        (DEFAULT_OBJECTS_MAP[activeObject.type] &&
                            DEFAULT_OBJECTS_MAP[activeObject.type].label) ||
                        locale.BACKGROUND
                    }
                    onChange={handleCheckbox}
                    disabled={disabled}
                >
                    <ColorPicker
                        label={locale.COLOR}
                        name={getTypeColorPicker(activeObject.type)}
                        value={color}
                        transparency={transparency}
                        transparencyPosition="left"
                        TextFieldProps={{
                            id: getTypeColorPicker(activeObject.type),
                            placeholder: 'Color',
                        }}
                        onChangeComplete={handleChangeComplete}
                        onChange={handleColorChange}
                        className="formControl"
                        showEyedropper={showEyedropper}
                        toggleEyeDropper={toggleEyeDropper}
                        disabled={disabled}
                    />
                </Collapse>
            </div>
        )
    );
}

ColorPickerWrapper.propTypes = {
    activeObject: object,
    canvas: object,
    disabled: bool,
    showEyedropper: bool,
    saveCanvasToHistory: func.isRequired,
    toggleEyeDropper: func,
};
export default WithCanvasContext(ColorPickerWrapper);
