import React, { useRef, useState, useEffect } from 'react';
import { object, func, bool } from 'prop-types';
import debouncePromise from 'debounce-promise-with-cancel';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import StrikethroughSIcon from '@material-ui/icons/StrikethroughS';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Form } from 'formik';
import { customFabric as fabric } from '../../../components/FabricComponents';
import FormikWrapper from '../../../components/FormikWrapper';
import FormikField from '../../../components/FormikField';
import { LowerCaseIcon } from '../../../constants/icons';
import { CapitalizeIcon } from '../../../constants/icons';
import { UpperCaseIcon } from '../../../constants/icons';
import Grid from '../../../components/Grid';
import { WithCanvasContext } from '../../../utils/context';
import { usePrevious } from '../../../utils/hooks';
import { saveCanvasState, setCanvasObjectParams } from '../../../utils/canvas';
import {
    CANVAS_ALLOWED_SHOW_OBJECTS,
    DEFAULT_TEXT_FORMATS,
} from '../../../constants/canvas';
import { FONTS_MAP, textParamsSchema } from '../../../schemas/text-params';
import {
    DEFAULT_FONT,
    SELECT_FIELD,
    DROPDOWN_FIELD,
    TEXT_FIELD,
    NUMBER_FIELD,
} from '../../../constants/variables';
import { locale } from '../../../constants/locales';
import {
    DEFAULT_FONT_SIZE,
    DEFAULT_TEXT_LINE_HEIGHT,
    DEFAULT_TEXT_SPACING,
    DEFAULT_MAX_CHARS_VALUE,
} from '../../../constants/sizes';
import validationSchema from '../../../schemas/validations/text-validation';
import classes from './TextPanel.module.scss';

function TextPanel({
    canvas,
    activeObject,
    saveCanvasToHistory,
    companyName,
    canvasIndex,
    disabled,
}) {
    const form = useRef(null);
    const [state, setState] = useState(false);
    const [initialValues, setValues] = useState({});

    const prevCanvasIndex = usePrevious(canvasIndex);
    const prevActiveObject = usePrevious(activeObject);

    const setProperties = async params => {
        try {
            if (params) {
                setCanvasObjectParams(activeObject, params);
                // TODO Ask client do they need this?
                // Change text width according to font size
                // if (activeObject) {
                //   const {textLines, width} = activeObject;
                //   const sizes = textLines.map((line, index) => {
                //     const {width} = activeObject.measureLine(index);
                //
                //     return width;
                //   });
                //   const [widthsSum] = sizes.sort((a, b) => b - a);
                //   if (typeof widthsSum === "number" && !Number.isNaN(widthsSum) && widthsSum < width) {
                //     activeObject.set({width: widthsSum * 1.25});
                //   }
                // }
                setState(!state);
                canvas.renderAll();
                await saveCanvasState(canvas, saveCanvasToHistory);
            }
        } catch (e) {
            console.info('Cannot apply properties to object');
        }
    };

    const handleFontAlign = async (event, textAlign) => {
        if (activeObject && textAlign && activeObject.textAlign !== textAlign) {
            await setProperties({ textAlign });
        }
    };

    const handleFontTransform = async (event, value) => {
        let transform = {};
        if (value === textParamsSchema.Transform.capitalize) {
            transform = {
                text: fabric.util.string.capitalize(activeObject.text),
                textTransform: value,
            };
        } else if (value === textParamsSchema.Transform.uppercase) {
            transform = {
                text: activeObject.text.toUpperCase(),
                textTransform: value,
            };
        } else if (value === textParamsSchema.Transform.lowercase) {
            transform = {
                text: activeObject.text.toLowerCase(),
                textTransform: value,
            };
        }
        await setProperties(transform);
    };

    const handleFontFormats = async (event, Formats) => {
        if (activeObject) {
            if (!Formats.length) {
                await setProperties({
                    ...DEFAULT_TEXT_FORMATS,
                    Formats,
                });
            } else {
                const isItalic = Formats.includes(textParamsSchema.Formats.italic);
                const isUnderline = Formats.includes(textParamsSchema.Formats.underline);
                const isLineThrough = Formats.includes(
                    textParamsSchema.Formats.linethrough,
                );
                await setProperties({
                    fontStyle: isItalic
                        ? textParamsSchema.Formats.italic
                        : textParamsSchema.Formats.normal,
                    underline: isUnderline,
                    linethrough: isLineThrough,
                    Formats,
                });
            }
        }
    };

    const setPropertiesAsync = async newValues => {
        const { maxCharacters } = newValues;
        // Handle Max Characters
        if (typeof maxCharacters === 'number' && maxCharacters > 0) {
            const newText = activeObject.get('text').slice(0, maxCharacters);
            activeObject.set('text', newText);
        }
        await setProperties(newValues);
        canvas.renderAll();
    };

    const setPropertiesDebounced = debouncePromise(setPropertiesAsync, 400);

    const submit = async (values, submitProps) => {
        const { setSubmitting, validateForm } = submitProps;
        const errors = await validateForm(values);
        if (!Object.keys(errors).length) {
            // Add debounce for form values changes
            const { charSpacing } = values;
            const newValues = {
                ...values,
                charSpacing: charSpacing * DEFAULT_FONT_SIZE,
            };
            await setPropertiesDebounced(newValues);
        }
        setSubmitting(false);
    };

    // Trigger submission
    const change = async () => {
        try {
            setPropertiesDebounced.cancel();
            await form?.current?.validateForm?.();
            await form?.current?.handleSubmit?.();
        } catch (e) {
            console.info(e);
        }
    };

    useEffect(() => {
        if (
            activeObject &&
            activeObject.type === CANVAS_ALLOWED_SHOW_OBJECTS.AnimatedTextbox
        ) {
            if (
                prevCanvasIndex !== canvasIndex ||
                activeObject?.objectId !== prevActiveObject?.objectId
            ) {
                const {
                    fontFamily,
                    fontSize,
                    charSpacing,
                    lineHeight,
                    maxCharacters,
                    textTransform,
                } = activeObject;
                let newFontFamily = fontFamily;
                // Backwards compatibility with old font names
                if (fontFamily === FONTS_MAP.DEPRECATED.MUSEO) {
                    newFontFamily = FONTS_MAP.MUSEO_SANS[500];
                } else if (fontFamily === FONTS_MAP.DEPRECATED.TRADE_GOTHIC) {
                    newFontFamily = FONTS_MAP.TRADE_GOTHIC[700];
                } else if (fontFamily === FONTS_MAP.DEPRECATED.AVENIR) {
                    newFontFamily = FONTS_MAP.AVENIR[500];
                } else if (fontFamily === FONTS_MAP.DEPRECATED.NOBEL) {
                    newFontFamily = FONTS_MAP.NOBEL[500];
                }
                setValues({
                    maxCharacters: maxCharacters || DEFAULT_MAX_CHARS_VALUE,
                    fontFamily: newFontFamily || DEFAULT_FONT,
                    fontSize: Number(fontSize),
                    charSpacing:
                        (charSpacing && charSpacing / DEFAULT_FONT_SIZE) ||
                        DEFAULT_TEXT_SPACING,
                    lineHeight: Number(lineHeight) || DEFAULT_TEXT_LINE_HEIGHT,
                    fontTransform:
                        Number(textTransform) || textParamsSchema.Transform.capitalize,
                });
            }
        }
    }, [activeObject, prevActiveObject, canvasIndex, prevCanvasIndex]);

    return (
        activeObject &&
        activeObject.type === CANVAS_ALLOWED_SHOW_OBJECTS.AnimatedTextbox && (
            <Grid container className={clsx(classes.textParameters, 'boxBorder')}>
                <Grid item xs={12}>
                    <Typography
                        className={classes.smallTitle}
                        variant="subtitle2"
                        align="left"
                        gutterBottom
                    >
                        <strong>{locale.TEXT}</strong>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <ToggleButtonGroup
                        value={activeObject.Formats}
                        onChange={handleFontFormats}
                        arial-label="text formatting"
                        className={classes.toggleButtons}
                    >
                        <ToggleButton
                            value="italic"
                            aria-label={textParamsSchema.Formats.italic}
                            classes={{
                                root: classes.toggleBtn,
                                selected: classes.toggleBtnSelected,
                                disabled: classes.toggleBtnDisabled,
                            }}
                            disabled={disabled}
                        >
                            <FormatItalicIcon />
                        </ToggleButton>
                        <ToggleButton
                            value="underlined"
                            aria-label={textParamsSchema.Formats.underline}
                            classes={{
                                root: classes.toggleBtn,
                                selected: classes.toggleBtnSelected,
                                disabled: classes.toggleBtnDisabled,
                            }}
                            disabled={disabled}
                        >
                            <FormatUnderlinedIcon />
                        </ToggleButton>
                        <ToggleButton
                            value="linethrough"
                            aria-label={textParamsSchema.Formats.linethrough}
                            classes={{
                                root: classes.toggleBtn,
                                selected: classes.toggleBtnSelected,
                                disabled: classes.toggleBtnDisabled,
                            }}
                            disabled={disabled}
                        >
                            <StrikethroughSIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <ToggleButtonGroup
                        value={activeObject.textAlign}
                        exclusive
                        onChange={handleFontAlign}
                        aria-label="text alignment"
                        className={classes.toggleButtons}
                    >
                        <ToggleButton
                            value={textParamsSchema.Alignment.left}
                            aria-label="left aligned"
                            classes={{
                                root: classes.toggleBtn,
                                selected: classes.toggleBtnSelected,
                                disabled: classes.toggleBtnDisabled,
                            }}
                            disabled={disabled}
                        >
                            <FormatAlignLeftIcon />
                        </ToggleButton>
                        <ToggleButton
                            value={textParamsSchema.Alignment.center}
                            aria-label="centered"
                            classes={{
                                root: classes.toggleBtn,
                                selected: classes.toggleBtnSelected,
                                disabled: classes.toggleBtnDisabled,
                            }}
                            disabled={disabled}
                        >
                            <FormatAlignCenterIcon />
                        </ToggleButton>
                        <ToggleButton
                            value={textParamsSchema.Alignment.right}
                            aria-label="right aligned"
                            classes={{
                                root: classes.toggleBtn,
                                selected: classes.toggleBtnSelected,
                                disabled: classes.toggleBtnDisabled,
                            }}
                            disabled={disabled}
                        >
                            <FormatAlignRightIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <ToggleButtonGroup
                        value={activeObject.textTransform}
                        exclusive
                        onChange={handleFontTransform}
                        aria-label="text transform"
                        className={classes.toggleButtons}
                    >
                        <ToggleButton
                            value={textParamsSchema.Transform.capitalize}
                            aria-label="capitalize"
                            classes={{
                                root: classes.toggleBtn,
                                selected: classes.toggleBtnSelected,
                                disabled: classes.toggleBtnDisabled,
                            }}
                            disabled={disabled}
                        >
                            <CapitalizeIcon />
                        </ToggleButton>
                        <ToggleButton
                            value={textParamsSchema.Transform.uppercase}
                            aria-label="uppercase"
                            classes={{
                                root: classes.toggleBtn,
                                selected: classes.toggleBtnSelected,
                                disabled: classes.toggleBtnDisabled,
                            }}
                            disabled={disabled}
                        >
                            <UpperCaseIcon />
                        </ToggleButton>
                        <ToggleButton
                            value={textParamsSchema.Transform.lowercase}
                            aria-label="lowercase"
                            classes={{
                                root: classes.toggleBtn,
                                selected: classes.toggleBtnSelected,
                                disabled: classes.toggleBtnDisabled,
                            }}
                            disabled={disabled}
                        >
                            <LowerCaseIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <FormikWrapper
                    ref={form}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize
                    onSubmit={submit}
                >
                    {({ values, handleSubmit }) =>
                        Object.keys(values).length && (
                            <Form
                                noValidate
                                className={classes.textPanelForm}
                                onSubmit={handleSubmit}
                                onChange={change}
                            >
                                <Grid container spacing={1}>
                                    <Grid item xs={7}>
                                        <FormikField
                                            label={locale.FONT}
                                            type={SELECT_FIELD}
                                            name="fontFamily"
                                            variant="outlined"
                                            className="formControl"
                                            options={
                                                textParamsSchema.CompanyFonts[companyName]
                                            }
                                            fullWidth
                                            select
                                            onChangeCallback={change}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <FormikField
                                            label={locale.SIZE}
                                            type={DROPDOWN_FIELD}
                                            name="fontSize"
                                            options={textParamsSchema.FontSize}
                                            fullWidth
                                            variant="outlined"
                                            onChangeCallback={change}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormikField
                                            label={locale.SPACING}
                                            type={TEXT_FIELD}
                                            name="charSpacing"
                                            fullWidth
                                            disabled={disabled}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormikField
                                            label={locale.LINE_HEIGHT}
                                            type={NUMBER_FIELD}
                                            name="lineHeight"
                                            fullWidth
                                            disabled={disabled}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <FormikField
                                            label={locale.MAX_CHARACTERS}
                                            type={TEXT_FIELD}
                                            name="maxCharacters"
                                            fullWidth
                                            disabled={disabled}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Form>
                        )
                    }
                </FormikWrapper>
            </Grid>
        )
    );
}

TextPanel.prototype = {
    activeObject: object,
    canvas: object,
    disabled: bool,
    saveCanvasToHistory: func.isRequired,
};

export default WithCanvasContext(TextPanel);
