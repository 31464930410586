import { bool, number, shape, string } from 'prop-types';

export default shape({
    id: string,
    createdAt: string,
    updatedAt: string,
    transitionName: string,
    index: number,
    duration: number,
    imageUrl: string,
    fadeIn: bool,
    fadeOut: bool,
    zoomIn: bool,
    zoomInRange: number,
    logo: string | null,
});
