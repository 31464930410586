import { generate } from 'shortid';
import {
    DEFAULT_RECT_COLOR,
    DEFAULT_BG_COLOR,
    DEFAULT_OBJECT_COLOR,
    DEFAULT_SNAP_ANGLE,
    DEFAULT_PLACEHOLDER_BG_COLOR,
    DEFAULT_PLACEHOLDER_BORDER_COLOR,
    DEFAULT_CROP_BG_COLOR,
    DEFAULT_LINE_COLOR,
    DEFAULT_ELLIPSE_COLOR,
    DEFAULT_BORDER_COLOR,
} from './variables';
import { COLORS } from './colors';
import { locale } from './locales';
import { textParamsSchema } from '../schemas/text-params';
import {
    CANVAS_GRID_SIZE,
    DEFAULT_TEXT_LINE_HEIGHT,
    DEFAULT_TEXT_SPACING,
} from './sizes';
export const VIDEOS_WRAPPER_ID = 'videosWrapper';

export const ACCEPT_FILE_TYPES = Object.freeze([
    'image/jpeg',
    'image/png',
    'image/gif',
    'video/mp4',
    'video/x-msvideo',
]);
export const CANVAS_GRID_OPTIONS = Object.freeze([
    CANVAS_GRID_SIZE,
    CANVAS_GRID_SIZE * 1.5,
    CANVAS_GRID_SIZE * 2,
]);
export const CANVAS_ADDITIONAL_PROPERTIES = Object.freeze([
    'slideId',
    'templateId',
    'projectId',
    'preview',
    'animation',
    'videoSrc',
    'videoDuration',
    'savedColor',
    'type',
    'Formats',
    'padding',
    'objectId',
    'objectName',
    'isLocked',
    'editable',
    'lockMovementY',
    'lockMovementX',
    'lockRotation',
    'lockScalingX',
    'lockScalingY',
    'lockSkewingX',
    'lockSkewingY',
    'textTransform',
    'maxCharacters',
    'previewUrl',
    'selectable',
    'evented',
    'templateCompany',
    'absolutePositioned',
    'canvasCurrentWidth',
    'canvasInitialWidth',
    'viewportTransform',
    'cropDimensions',
    'clipFor',
    'lockScalingFlip',
    'storedAnimation',
    'minScaleLimit',
    '_controlsVisibility',
    'hasGrid',
    'objectSnapping',
    'gridSize',
    'savedStrokeWidth',
    'strokeUniform',
    'objectCaching',
    'zoomIn',
    'zoomInValue',
    'dimensions',
    'snapAngle',
    'enableColor',
    'enableBorderColor',
    'typingAnimation',
    'typingAnimationInterval',
]);
export const CANVAS_CLONE_PROPERTIES = Object.freeze([
    'width',
    'height',
    'animation',
    'storedAnimation',
    'videoSrc',
    'videoDuration',
    'savedColor',
    'type',
    'Formats',
    'padding',
    'objectId',
    'objectName',
    'isLocked',
    'lockMovementY',
    'lockMovementX',
    'lockRotation',
    'lockScalingX',
    'lockScalingY',
    'lockSkewingX',
    'lockSkewingY',
    'textTransform',
    'maxCharacters',
    'selectable',
    'evented',
    'viewportTransform',
    'cropDimensions',
    'lockScalingFlip',
    'storedAnimation',
    'minScaleLimit',
    '_controlsVisibility',
    'hasGrid',
    'savedStrokeWidth',
    'strokeUniform',
    'objectCaching',
    'zoomIn',
    'zoomInValue',
    'textEffect',
    'textTypingEffectEffectValue',
    'zoomInValue',
    'stroke',
    'strokeWidth',
    'savedStrokeWidth',
    'borderOpacityWhenMoving',
    'strokeUniform',
    'objectCaching',
    'dimensions',
    'snapAngle',
    'clipPath',
]);

export const CANVAS_ALLOWED_COLORED_OBJECTS = Object.freeze([
    'AnimatedTextbox',
    'AnimatedRect',
    'Canvas',
    'line',
    'ellipse',
]);

export const CHECK_AVAILABLE_COLOR_PICKER = Object.freeze([
    'AnimatedTextbox',
    'AnimatedRect',
    'Canvas',
    'line',
    'ellipse',
]);

export const CANVAS_ALLOWED_BORDER_COLORED_OBJECTS = Object.freeze([
    'AnimatedRect',
    'ellipse',
]);

export const CANVAS_LOCK_OBJECT = Object.freeze([
    'lockMovementY',
    'lockMovementX',
    'lockRotation',
    'lockScalingFlip',
    'lockScalingX',
    'lockScalingY',
    'lockSkewingX',
    'lockSkewingY',
    'lockUniScaling',
]);

export const CANVAS_ALLOWED_SHOW_OBJECTS = Object.freeze({
    AnimatedPlaceholder: 'AnimatedPlaceholder',
    AnimatedTextbox: 'AnimatedTextbox',
    AnimatedRect: 'AnimatedRect',
});

export const CANVAS_TYPE_OBJECTS = Object.freeze({
    animatedPlaceholder: 'AnimatedPlaceholder',
    animatedTextbox: 'AnimatedTextbox',
    animatedImage: 'AnimatedImage',
    image: 'image',
    videoImage: 'VideoImage',
    animatedRect: 'AnimatedRect',
    crop: 'Crop',
    line: 'line',
    group: 'group',
    ellipse: 'ellipse',
    polyline: 'polyline',
    activeSelection: 'activeSelection',
    canvas: 'Canvas',
});

export const CANVAS_CROP_TYPES = Object.freeze([
    CANVAS_TYPE_OBJECTS.animatedImage,
    CANVAS_TYPE_OBJECTS.videoImage,
    'image',
]);

export const CANVAS_SCALE_TYPES = Object.freeze([
    ...CANVAS_CROP_TYPES,
    CANVAS_TYPE_OBJECTS.group,
    CANVAS_TYPE_OBJECTS.animatedTextbox,
]);

export const CANVAS_TEXT_TYPING_EFFECT_TYPES = Object.freeze([
    CANVAS_TYPE_OBJECTS.animatedTextbox,
]);

export const DEFAULT_OBJECTS_MAP = Object.freeze({
    AnimatedTextbox: {
        label: `${locale.OBJECTS.TEXT} ${locale.COLOR.toLowerCase()}`,
        color: DEFAULT_OBJECT_COLOR,
    },
    AnimatedRect: {
        label: `${locale.OBJECTS.RECT} ${locale.COLOR.toLowerCase()}`,
        color: DEFAULT_RECT_COLOR,
    },
    line: {
        label: `${locale.OBJECTS.LINE} ${locale.COLOR.toLowerCase()}`,
        color: DEFAULT_LINE_COLOR,
    },
    ellipse: {
        label: `${locale.OBJECTS.ELLIPSE} ${locale.COLOR.toLowerCase()}`,
        color: DEFAULT_ELLIPSE_COLOR,
    },
    Canvas: {
        label: locale.BACKGROUND,
        color: DEFAULT_BG_COLOR,
    },
});

export const generateId = () => ({
    objectId: generate(),
});

export const IMAGE_CREATE_OPTIONS = () => ({
    crossOrigin: 'anonymous',
    originX: 'left',
    originY: 'top',
    top: 0,
    left: 0,
    opacity: 1,
    scaleX: 1,
    scaleY: 1,
    snapAngle: DEFAULT_SNAP_ANGLE,
    objectCaching: true,
    lockScalingFlip: true,
    lockUniScaling: true,
    objectId: generate(),
});

export const STROKE_OPTIONS = Object.freeze({
    strokeWidth: 1,
    strokeDashArray: null,
    strokeUniform: true,
});

export const LINE_OPTIONS = Object.freeze({
    stroke: DEFAULT_PLACEHOLDER_BORDER_COLOR,
    fill: '',
    ...STROKE_OPTIONS,
});

export const TEXT_CREATE_OPTIONS = () => ({
    text: 'Text',
    textAlign: 'center',
    verticalAlign: 'center',
    height: 90,
    fontSize: 48,
    charSpacing: DEFAULT_TEXT_SPACING,
    lineHeight: DEFAULT_TEXT_LINE_HEIGHT,
    textTransform: textParamsSchema.Transform.capitalize,
    padding: 10,
    top: 0,
    left: 0,
    underline: false,
    fill: DEFAULT_OBJECT_COLOR,
    savedColor: null,
    snapAngle: DEFAULT_SNAP_ANGLE,
    Formats: [],
    splitByGrapheme: true,
    strokeUniform: true,
    objectCaching: false,
    objectId: generate(),
    isLocked: false,
    originY: 'center',
    originX: 'center',
});

export const RECT_CREATE_OPTIONS = canvasWidth => ({
    height: 120,
    width: canvasWidth * 0.5,
    left: 0,
    top: 0,
    opacity: 1,
    padding: 0,
    snapAngle: DEFAULT_SNAP_ANGLE,
    fill: DEFAULT_RECT_COLOR,
    savedColor: null,
    stroke: DEFAULT_BORDER_COLOR,
    strokeWidth: 0,
    savedStrokeWidth: 1,
    borderOpacityWhenMoving: 1,
    strokeUniform: true,
    objectCaching: true,
    objectId: generate(),
});

export const LINE_CREATE_OPTIONS = (nonGridLine = false) => ({
    opacity: 1,
    savedColor: null,
    padding: 8,
    stroke: DEFAULT_LINE_COLOR,
    fill: null,
    width: 1,
    strokeWidth: 1,
    selectable: true,
    lockScalingFlip: nonGridLine,
    objectId: generate(),
    strokeUniform: true,
    objectCaching: false,
});

export const ELLIPSE_CREATE_OPTIONS = () => ({
    left: 0,
    top: 0,
    originX: 'left',
    originY: 'top',
    rx: 100,
    ry: 125,
    height: 100,
    width: 150,
    opacity: 1,
    fill: DEFAULT_ELLIPSE_COLOR,
    backgroundColor: null,
    savedColor: null,
    stroke: DEFAULT_BORDER_COLOR,
    strokeWidth: 0,
    savedStrokeWidth: 1,
    objectId: generate(),
    strokeUniform: true,
    objectCaching: true,
});

export const DROP_ICON_CODE = '\uE8D7';
export const DROP_ICON_OPTIONS = dropObject =>
    Object.freeze({
        fontSize: 40,
        fontFamily: 'Material Icons',
        originX: 'center',
        originY: 'center',
        scaleX: 1 / dropObject.scaleX,
        scaleY: 1 / dropObject.scaleY,
        fill: COLORS.hardGray,
    });

export const PLACEHOLDER_DEFAULT_OPTIONS = Object.freeze({
    fill: DEFAULT_PLACEHOLDER_BG_COLOR,
    stroke: DEFAULT_PLACEHOLDER_BORDER_COLOR,
});

export const PLACEHOLDER_DRAGOVER_OPTIONS = Object.freeze({
    fill: COLORS.blackOpacityHex,
    stroke: COLORS.primary,
});

export const PLACEHOLDER_CREATE_OPTIONS = canvas => ({
    originX: 'left',
    originY: 'top',
    height: (canvas.scaledDimensions.width || canvas.width) * 0.5,
    width: canvas.scaledDimensions.width || canvas.width,
    top: 0.5,
    left: 0.5,
    opacity: 1,
    scaleX: 1,
    scaleY: 1,
    zoomX: 1,
    zoomY: 1,
    snapAngle: DEFAULT_SNAP_ANGLE,
    objectId: generate(),
    objectCaching: true,
    ...PLACEHOLDER_DEFAULT_OPTIONS,
});

export const CLIPPATH_CREATE_OPTIONS = object =>
    object && {
        width: object.width,
        height: object.height,
        top: object.top,
        left: object.left,
        scaleX: object.scaleX,
        scaleY: object.scaleY,
        zoomY: object.zoomY,
        zoomX: object.zoomX,
        translateX: object.translateX,
        translateY: object.translateY,
        oCoords: object.oCoords,
        aCoords: object.aCoords,
        viewportTransform: object.viewportTransform,
        angle: object.angle,
        zoomIn: object.zoomIn,
        zoomInValue: object.zoomInValue,
        typingAnimation: object.typingAnimation,
        typingAnimationInterval: object.typingAnimationInterval,
        animation: object.animation,
        dimensions: object.dimensions,
        objectId: generate(),
        originX: object.originX,
        originY: object.originY,
        ...STROKE_OPTIONS,
    };

export const STOP_MODIFICATION_PROPERTIES = {
    lockMovementX: true,
    lockMovementY: true,
    lockScalingX: true,
    lockScalingY: true,
    lockRotation: true,
};

export const UNLOCKED_OBJECT_DEFAULT_CONFIGURATION = {
    isLocked: false,
    editable: true,
    selectable: true,
    lockMovementY: false,
    lockMovementX: false,
    lockRotation: false,
    lockScalingX: false,
    lockScalingY: false,
    lockSkewingX: false,
    lockSkewingY: false,
};

export const LOCKED_OBJECT_DEFAULT_CONFIGURATION = {
    isLocked: true,
    editable: false,
    selectable: false,
    lockMovementY: true,
    lockMovementX: true,
    lockRotation: true,
    lockScalingX: true,
    lockScalingY: true,
    lockSkewingX: true,
    lockSkewingY: true,
};

export const CROP_OPTIONS = () => ({
    fill: DEFAULT_CROP_BG_COLOR,
    originX: 'left',
    originY: 'top',
    opacity: 1,
    strokeUniform: true,
    objectCaching: true,
});

export const DEFAULT_TEXT_FORMATS = Object.freeze({
    fontWeight: textParamsSchema.Formats.regularWeight,
    fontStyle: textParamsSchema.Formats.normal,
    underline: false,
    linethrough: false,
});

export const TEXT_BOUND_POINTS = Object.freeze({
    tl: false, // top-left
    mtr: true, // middle-top-rotate
    mt: false, // middle-top
    tr: false, // top-right
    ml: true, // middle-left
    mr: true, // middle-right
    bl: false, // bottom-left
    mb: false, // middle-bottom
    br: false, // bottom-right
});

export const LINE_BOUND_POINTS = Object.freeze({
    tl: false, // top-left
    mtr: true, // middle-top-rotate
    mt: false, // middle-top
    tr: false, // top-right
    ml: true, // middle-left
    mr: true, // middle-right
    bl: false, // bottom-left
    mb: false, // middle-bottom
    br: false, // bottom-right
});

export const NO_ROTATE_BOUND_POINTS = Object.freeze({
    tl: true, // top-left
    mtr: false, // middle-top-rotate
    mt: true, // middle-top
    tr: true, // top-right
    ml: true, // middle-left
    mr: true, // middle-right
    bl: true, // bottom-left
    mb: true, // middle-bottom
    br: true, // bottom-right
});

export const NO_ROTATE_AND_SCALE_BOUND_POINTS = Object.freeze({
    tl: true, // top-left
    mtr: false, // middle-top-rotate
    mt: false, // middle-top
    tr: true, // top-right
    ml: false, // middle-left
    mr: false, // middle-right
    bl: true, // bottom-left
    mb: false, // middle-bottom
    br: true, // bottom-right
});

export const IMAGE_BOUND_POINTS = Object.freeze({
    tl: true, // top-left
    mtr: true, // middle-top-rotate
    mt: false, // middle-top
    tr: true, // top-right
    ml: false, // middle-left
    mr: false, // middle-right
    bl: true, // bottom-left
    mb: false, // middle-bottom
    br: true, // bottom-right
});

export const Direction = Object.freeze({
    TOP: 'top',
    BOTTOM: 'bottom',
    LEFT: 'left',
    RIGHT: 'right',
});
export const KEYDOWN_EVENT_CODES = Object.freeze({
    LEFT: 37,
    TOP: 38,
    RIGHT: 39,
    DOWN: 40,
    DELETE: 46,
    MAC_DELETE: 8,
    ESCAPE: 27,
    C: 67,
    V: 86,
    A: 65,
    L: 76,
    O: 79,
    R: 82,
    T: 84,
    I: 73,
});
export const KEYDOWN_COMBINATION_EVENT_CODES = Object.freeze({
    CTRL_KEY: 'ctrlKey',
    META_KEY: 'metaKey',
    SHIFT_KEY: 'shiftKey',
    ALT_KEY: 'altKey',
});

export const DEFAULT_DIRECTION_STEP = 1;
export const OMIT_INPUT_TYPES = Object.freeze([
    'text',
    'password',
    'number',
    'email',
    'tel',
    'url',
    'search',
    'date',
    'datetime',
    'datetime-local',
    'time',
    'month',
    'week',
]);
