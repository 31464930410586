import React, { useState } from 'react';
import { array, func } from 'prop-types';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '../../components/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import DeleteIcon from '@material-ui/icons/Close';
import VideoIcon from '@material-ui/icons/Videocam';
import Progress from '../Progress';
import Popup from '../Popup';
import Alert from '../Alert';
import Chip from '../Chip';
import { sleep } from '../../utils/common';
import classes from './ProcessingQueueList.module.scss';
import { locale } from '../../constants/locales';

const ProcessingQueueList = ({ processingQueue, deleteFromQueue, getQueueProgress }) => {
    const [title, setTitle] = useState('');
    const [params, setParams] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [openAlert, setAlertOpen] = useState(false);

    function handleAlertOpen(data, projectTitle) {
        if (typeof data === 'object') {
            setParams(data);
        }
        if (projectTitle) {
            setTitle(projectTitle);
        }
        setAlertOpen(true);
    }

    function handleAlertClose() {
        setAlertOpen(false);
    }

    async function handleAlertAction() {
        handleAlertClose();
        setDisabled(true);
        await deleteFromQueue(params);
        await sleep(1000);
        setDisabled(false);
        await getQueueProgress();
    }

    return (
        processingQueue &&
        !!Object.keys(processingQueue).length && (
            <div className={classes.processingQueueWrapper}>
                <Popup
                    btnClassName={classes.videoButton}
                    iconButton={<VideoIcon classes={{ root: classes.videoIcon }} />}
                    menuLeft
                >
                    <div className={classes.wrapperListHeader}>
                        <ListSubheader
                            component="div"
                            classes={{
                                root: classes.ListHeader,
                            }}
                            id="processing-queue-subheader"
                        >
                            Rendering Queue
                        </ListSubheader>
                    </div>
                    <List className={classes.List} disablePadding component="ul">
                        {Object.keys(processingQueue).length ? (
                            processingQueue.map(item => (
                                <ListItem
                                    key={item.id}
                                    className={classes.ListItem}
                                    alignItems="flex-start"
                                >
                                    <div className={classes.itemRow}>
                                        <div className={classes.textWrapper}>
                                            <Typography
                                                className={classes.text}
                                                variant="h5"
                                            >
                                                {item?.project?.title} ({item.type})
                                            </Typography>
                                            <Chip
                                                label={item?.project?.projectType?.toLowerCase()}
                                                size="small"
                                                variant="outlined"
                                                className={classes.itemBadge}
                                            />
                                        </div>
                                        <IconButton
                                            aria-label="remove"
                                            className={classes.marginLeft}
                                            onClick={() =>
                                                handleAlertOpen(
                                                    {
                                                        projectId: item.project.id,
                                                        queueId: item.id,
                                                        type: item.type,
                                                    },
                                                    item.project.title,
                                                )
                                            }
                                            disabled={disabled}
                                            disableRipple
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                    <div className={classes.processing}>
                                        <Progress
                                            relative
                                            progress={item.progress}
                                            projectId={item.project.id}
                                        />
                                        <Typography
                                            className={classes.text}
                                            variant="subtitle2"
                                        >
                                            {item.progress > 0
                                                ? `Complete ${item.progress}%`
                                                : `${locale.IN_LINE}`}
                                        </Typography>
                                    </div>
                                </ListItem>
                            ))
                        ) : (
                            <Typography align="left">No items</Typography>
                        )}
                    </List>
                </Popup>
                <Alert
                    alertConfig={{
                        alertTitle: locale.CANCEL_RENDERING,
                        alertText: locale.Messages.CONFIRM_CANCEL_RENDERING(title),
                        alertBtnText: locale.CONFIRM,
                        alertBtnColor: 'primary',
                        actionClick: handleAlertAction,
                        cancelAction: handleAlertClose,
                    }}
                    open={openAlert}
                />
            </div>
        )
    );
};

ProcessingQueueList.propTypes = {
    processingQueue: array.isRequired,
    deleteFromQueue: func.isRequired,
    getQueueProgress: func.isRequired,
};

export default ProcessingQueueList;
