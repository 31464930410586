import React, { useRef } from 'react';
import clsx from 'clsx';
import { arrayOf, bool, number, string } from 'prop-types';
import SlideObject from '../../../types/Slide';
import {
    SLIDE_FADE_DEFAULT_DURATION,
    BUILD_FULL_FILE_PATH,
} from '../../../constants/slides';
import { roundNumber } from '../../../utils/common';
import classes from './PreviewCarousel.module.scss';
import SlideLogo from '../../../components/SlideLogo';

const CarouselSlide = ({
    index,
    slides,
    transitionTimeout,
    slideDuration,
    stopVideo,
    className,
    isLast,
   	company
}) => {
    const elementRef = useRef(null);
    const isVideo = slides[index] && slides[index].imageUrl?.indexOf('.mp4') >= 0;
    const SlideTag = isVideo ? 'video' : 'img';
    if (stopVideo) {
        if (elementRef.current && elementRef.current.nodeName === 'VIDEO') {
            if (!elementRef.current.paused) {
                elementRef.current.pause();
            }
        }
    }

    let durations = slideDuration;
    // Check if last slide has "fadeOut" slide
    if (isLast && slides[index].fadeOut) {
        durations += SLIDE_FADE_DEFAULT_DURATION;
    }
    if (slides[index + 1]) {
        const halfTransition = Number(slides[index + 1].transitionDuration);
        durations += roundNumber(halfTransition, 2);
    }
    const transitionDuration = stopVideo ? 0 : `${transitionTimeout}s`;
    const animationDuration = stopVideo ? 0 : `${durations}s`;
    const style = slides[index].zoomIn && !stopVideo ? { animationDuration } : {};
    const classKey =
        slides[index].zoomIn && !stopVideo
            ? `scaling-${Math.round(+slides[index].zoomInRange * 100)}`
            : '';


	return (
        <div className={clsx(classes.SlideWrapper)} style={{ transitionDuration }}>
            <SlideTag
                className={clsx(className, classes[classKey], 'slideTag')}
                style={style}
                ref={elementRef}
                src={BUILD_FULL_FILE_PATH(slides[index].imageUrl)}
                autoPlay
                muted
            />
			{slides[index].logo && (
				<SlideLogo company={company} logoType={slides[index].logo} logoPosition={slides[index].logoPosition} />
			)}
        </div>
    );
};

CarouselSlide.propTypes = {
    index: number,
    slides: arrayOf(SlideObject),
	company: string.isRequired,
    transitionTimeout: number,
    className: string,
    stopVideo: bool,
    isLast: bool,
};

export default CarouselSlide;
