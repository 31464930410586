import React, { useState } from 'react';
import clsx from 'clsx';
import { any, bool, func, oneOfType, number, string, arrayOf } from 'prop-types';
import SaveIcon from '@material-ui/icons/Save';
import ProcessIcon from '@material-ui/icons/Cached';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import ImageIcon from '@material-ui/icons/Image';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Btn from '../Button';
import Progress from '../Progress';
import Alert from '../Alert';
import UndoRedoButtons from '../UndoRedoButtons';
import Checkbox from '../Fields/Checkbox';
import DownloadFileType from '../DownloadFileType';
import { STATUSES_TEMPLATE } from '../../constants/statuses';
import TemplateObject from '../../types/Template';
import { VIDEO_SIZES } from '../../constants/videos';
import VideoFormat from '../VideoFormat';
import { locale } from '../../constants/locales';
import classes from './ActionBar.module.scss';

function ActionBar(props) {
    const {
        // processVideo,
        editorMode = false,
        activeSellerDownloadButton,
        storyDownloadButton,
        children,
        go,
        back,
        projectId,
        processVideo,
        templateId,
        template,
        getTemplateById,
        projectVideo,
        goBackLink,
        showPreview,
        showReplay,
        showInstagramMode,
        instagramMode,
        setInstagramMode,
        isPlaying,
        replayPreview,
        undoRedoButtons,
        undo,
        redo,
        canvasIndex,
        canvasHistoryLength,
        showPublish,
        showArchive,
        publishTemplate,
        archiveTemplate,
        withValidation = false,
        handleFormUpdate,
        isVisible = true,
        processVideoDisabled = false,
        currentSlide,
        slides,
        animationPlaying = false,
    } = props;
    const [openAlert, setAlertOpen] = useState(false);
    const [openStoryDownloadAlert, setStoryDownloadAlertOpen] = useState(false);
    const [alertConfig, setAlertConfig] = useState({
        alertTitle: '',
        cancelAction: () => {},
    });
    const isTemplatePublished =
        template && template.status === STATUSES_TEMPLATE.PUBLISHED;
    const isTemplateDraft = template && template.status === STATUSES_TEMPLATE.DRAFT;

    async function validateForm() {
        if (withValidation) {
            try {
                await handleFormUpdate();
            } catch (e) {
                console.error('Error during form validation!', e);
            }
        }
    }

    function handleAlertClose() {
        setAlertOpen(false);
        setStoryDownloadAlertOpen(false);
    }

    async function handleProcessVideo() {
        setAlertConfig({
            alertTitle: locale.ModalHeaders.CHOOSE_RESOLUTION_OF_RENDERING,
            cancelAction: handleAlertClose,
        });
        setAlertOpen(true);
        if (activeSellerDownloadButton) {
            await validateForm();
        }
    }

    function handleFormatVideo(size) {
        if (projectId) {
            processVideo(projectId, size);
            handleAlertClose();
        }
    }

    async function handlePublishAlert() {
        try {
            await handleFormUpdate();
            const res = await publishTemplate(templateId);
            await getTemplateById(templateId);
            handleAlertClose();
            if (res?.id) {
                go('/templates');
            }
        } catch (e) {
            console.error(e);
            handleAlertClose();
        }
    }

    async function handleArchiveAlert() {
        try {
            await handleFormUpdate();
            const res = await archiveTemplate(templateId);
            await getTemplateById(templateId);
            handleAlertClose();
            if (res.id) {
                go('/templates');
            }
        } catch (e) {
            console.error(e);
            handleAlertClose();
        }
    }

    function handlePublish() {
        setAlertConfig({
            alertTitle: locale.actionString(locale.PUBLISH, 'template'),
            alertText: locale.Messages.PUBLISH_ALERT,
            alertBtnText: locale.OK,
            alertBtnColor: 'primary',
            actionClick: handlePublishAlert,
            cancelAction: handleAlertClose,
        });
        setAlertOpen(true);
    }

    function handleArchive() {
        setAlertConfig({
            alertTitle: locale.actionString(locale.ARCHIVE, 'template'),
            alertText: locale.Messages.ARCHIVE_ALERT,
            alertBtnText: locale.OK,
            alertBtnColor: 'primary',
            actionClick: handleArchiveAlert,
            cancelAction: handleAlertClose,
        });
        setAlertOpen(true);
    }

    async function handleRedirect(event) {
        event.preventDefault();
        event.stopPropagation();
        validateForm().catch(e => console.info(e));
        if (projectId && !templateId) {
            go(`/${projectId}/project-preview`);
        }
        if (templateId && !projectId) {
            go(`/${templateId}/template-preview`);
        }
    }

    async function handleGoBack(event) {
        event.preventDefault();
        event.stopPropagation();
        validateForm().catch(e => console.info(e));
        if (typeof goBackLink === 'boolean') {
            back();
        } else {
            go(goBackLink);
        }
    }

    async function handleStoryDownload(event) {
        if (slides && slides.length > 1) {
            event.preventDefault();
            setAlertConfig({
                alertTitle: locale.ModalHeaders.HOW_DOWNLOAD_STORY,
                cancelAction: handleAlertClose,
            });
            setStoryDownloadAlertOpen(true);
        }
    }

    function handleStoryStartDownload() {
        handleAlertClose();
    }

    return (
        <div className={classes.actionBar}>
            {projectId && <Progress projectId={projectId} />}
            {isVisible && (
                <div className={classes.actionBarWrap}>
                    {goBackLink && (
                        <div className={clsx([classes.btnSection, classes.leftAlign])}>
                            <Btn
                                btnType="defaultBtn"
                                variant="contained"
                                onClick={handleGoBack}
                                className={classes.buttonBack}
                                size="small"
                                disableRipple
                                disableFocusRipple
                                onlyIcon={<ArrowBackIcon />}
                            />
                        </div>
                    )}
                    {undoRedoButtons && canvasIndex >= 0 && (
                        <UndoRedoButtons
                            undo={undo}
                            redo={redo}
                            canvasIndex={canvasIndex}
                            canvasHistoryLength={canvasHistoryLength}
                            disabled={animationPlaying}
                        />
                    )}

                    <div
                        className={clsx(
                            editorMode && [classes.btnSection, classes.rightAlign],
                        )}
                    >
                        {showInstagramMode && (
                            <Checkbox
                                checked={instagramMode}
                                label="Instagram mode"
                                labelPlacement="start"
                                onChange={setInstagramMode}
                                className={classes.instagramModeCheckbox}
                            />
                        )}
                        {showPreview && (
                            <Btn
                                text={locale.PREVIEW}
                                size="small"
                                variant="contained"
                                onClick={handleRedirect}
                                btnType="primaryBtn"
                                disabled={
                                    storyDownloadButton || activeSellerDownloadButton
                                        ? slides?.length === 0
                                        : false
                                }
                            />
                        )}
                        {showPublish && isTemplateDraft && (
                            <Btn
                                text={locale.PUBLISH}
                                size="small"
                                variant="contained"
                                onClick={handlePublish}
                                btnType="primaryBtn"
                            />
                        )}
                        {showArchive && isTemplatePublished && (
                            <Btn
                                text={locale.ARCHIVE}
                                size="small"
                                variant="contained"
                                onClick={handleArchive}
                                btnType="primaryBtn"
                            />
                        )}
                        {showReplay && (
                            <Btn
                                disabled={isPlaying}
                                text={locale.REPLAY}
                                size="small"
                                variant="contained"
                                onClick={replayPreview}
                                btnType="primaryBtn"
                            />
                        )}
                        {(activeSellerDownloadButton || storyDownloadButton) && (
                            <Btn
                                text={locale.PROCESS}
                                variant="contained"
                                size="small"
                                disabled={processVideoDisabled}
                                onClick={handleProcessVideo}
                                btnType="primaryBtn"
                            >
                                <ProcessIcon />
                            </Btn>
                        )}
                        {activeSellerDownloadButton && (
                            <Btn
                                btnType="primaryBtn"
                                href={projectVideo ?? ''}
                                text={locale.DOWNLOAD}
                                variant="contained"
                                size="small"
                                disabled={!projectVideo || processVideoDisabled}
                            >
                                <SaveIcon />
                            </Btn>
                        )}
                        {storyDownloadButton && (
                            <Btn
                                btnType="primaryBtn"
                                href={currentSlide?.downloadUrl ?? ''}
                                text={locale.DOWNLOAD}
                                variant="contained"
                                size="small"
                                disabled={
                                    (!projectVideo && !currentSlide?.downloadUrl) ||
                                    processVideoDisabled
                                }
                                onClick={handleStoryDownload}
                            >
                                <SaveIcon />
                            </Btn>
                        )}
                    </div>
                    {children}
                    <Alert alertConfig={alertConfig} open={openAlert}>
                        <div className={classes.boxButtons}>
                            <VideoFormat
                                buttonTitle="FullHD"
                                size="(1080 x 1920)"
                                onClick={() => handleFormatVideo(VIDEO_SIZES.FHD)}
                            />
                            <VideoFormat
                                buttonTitle="4K"
                                size="(2160 x 3840)"
                                onClick={() => handleFormatVideo(VIDEO_SIZES.WQHD)}
                            />
                        </div>
                    </Alert>
                    <Alert alertConfig={alertConfig} open={openStoryDownloadAlert}>
                        <div>
                            {projectVideo && (
                                <DownloadFileType
                                    text={locale.ModalButtons.CURRENT_SLIDE}
                                    href={currentSlide?.downloadUrl ?? ''}
                                    disabled={!currentSlide?.downloadUrl}
                                    onClick={handleStoryStartDownload}
                                >
                                    <ImageIcon />
                                </DownloadFileType>
                            )}
                            {currentSlide && !!Object.keys(currentSlide).length && (
                                <DownloadFileType
                                    text={locale.ModalButtons.WHOLE_PROJECT}
                                    href={projectVideo ?? ''}
                                    disabled={!projectVideo}
                                    onClick={handleStoryStartDownload}
                                >
                                    <BurstModeIcon />
                                </DownloadFileType>
                            )}
                        </div>
                    </Alert>
                </div>
            )}
        </div>
    );
}

ActionBar.propTypes = {
    editorMode: bool,
    showPreview: bool,
    showReplay: bool,
    isPlaying: bool,
    isVisible: bool,
    withValidation: bool,
    replayPreview: func,
    showInstagramMode: bool,
    setInstagramMode: func,
    instagramMode: bool,
    goBackLink: oneOfType([string, bool]),
    projectVideo: string,
    goBackText: string,
    templateId: string,
    template: TemplateObject,
    // processVideo: func,
    activeSellerDownloadButton: bool,
    storyDownloadButton: bool,
    undoRedoButtons: bool,
    undo: func,
    redo: func,
    canvasIndex: number,
    canvasHistoryLength: number,
    showPublish: bool,
    showArchive: bool,
    publishTemplate: func,
    archiveTemplate: func,
    getTemplateById: func,
    children: any,
    handleFormUpdate: func,
    go: func.isRequired,
    processVideoDisabled: bool,
    animationPlaying: bool,
    currentSlide: any,
    slides: arrayOf(any),
};

export default ActionBar;
