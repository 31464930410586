import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { version } from '../../../package.json';
import persistStore from './app/store/configureStore';
import { removeUserLocalData } from './app/utils/common';
const { persistor } = persistStore();

// Clear previously stored used data if app version changes
const appVersion = localStorage.getItem('appVersion');
if (appVersion) {
    if (appVersion !== version) {
        localStorage.setItem('appVersion', version);
        removeUserLocalData();
        if (persistor) {
            persistor
                .purge()
                .then(() => persistor.persist())
                .catch(e => console.info(e?.message));
        }
        alert('Your app has been updated. Please login back to app after redirect.');
        window.location.replace('/');
    }
} else {
    localStorage.setItem('appVersion', version);
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
