import React, { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { arrayOf, bool, func, string } from 'prop-types';
import debouncePromise from 'debounce-promise-with-cancel';
import { Form } from 'formik';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import FormikWrapper from '../../components/FormikWrapper';
import FormikField from '../../components/FormikField';
import Btn from '../../components/Button';
import IconButton from '../../components/IconButton';
import Spinner from '../../components/Spinner';
import withFormDialog from '../../components/withFormDialog';
import Alert from '../../components/Alert';
import { useMount, usePrevious } from '../../utils/hooks';
import AuthContext from '../../utils/auth-context';
import { buildOptions } from '../../utils/common';

import { PlusIcon } from '../../constants/icons';
import {
    MULTIPLE_SELECT_FIELD,
    SEARCH_FIELD,
    TEXT_FIELD,
} from '../../constants/variables';
import { COMPANIES_OPTIONS } from '../../constants/companies';
import { ALL_TEMPLATES_CATEGORY_MOCK } from '../../constants/categories';
import { locale } from '../../constants/locales';
import validationSchema from '../../schemas/validations/add-category';
import Category from '../../types/Category';
import classes from './Categories.module.scss';

function Categories({
    onChange,
    userId,
    categories,
    isLoading,
    getCategories,
    createNewCategory,
    editCategory,
    deleteCategory,
    openFormDialog,
    filtersOpen,
    noTemplates,
    hidden,
}) {
    const { companyNames } = useContext(AuthContext);
    const form = useRef(null);
    const prevCategories = usePrevious(categories);
    const [initialValues] = useState({
        search: '',
    });
    const [inputRef, setInputRef] = useState(null);
    const [inputOpened, setInputOpened] = useState(false);
    const [lastActiveField, setLastActiveField] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertConfig, setAlertConfig] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const setSearchRef = ref => setInputRef(ref);

    useMount(() => {
        const fetchCategories = async () => {
            await getCategories();
        };
        fetchCategories();
    });

    // Choose All Categories on initial mount
    useEffect(() => {
        if ((!prevCategories || !prevCategories.length) && categories.length) {
            setSelectedCategory(ALL_TEMPLATES_CATEGORY_MOCK);
        }
    }, [prevCategories, categories]);

    async function handleAddNewCategory(formData) {
        const res = await createNewCategory({ ...formData, createdBy: userId });
        await getCategories();
        return res;
    }

    const handleSearch = async searchText => {
        await getCategories({ search: searchText });
    };

    const onKeyPress = async ev => {
        if (ev && ev?.key === 'Enter') {
            ev.preventDefault();
            setLastActiveField(ev.target.name);
            await handleSearch(ev.target.value);
        }
    };

    const onInputToggle = async inputState => {
        setInputOpened(inputState);
        if (!inputState) {
            await getCategories();
            // Choose All Categories - un-comment if needed
            // handleSelectCategory(null, ALL_TEMPLATES_CATEGORY_MOCK);
        }
    };

    const searchDebounced = debouncePromise(handleSearch, 400);

    const onInputChange = async ({ target }) => {
        //  Search categories on input -  un-comment if needed
        searchDebounced.cancel();
        await searchDebounced(target?.value);
    };

    const submit = async (values, submitProps) => {
        const { setSubmitting, validateForm } = submitProps;
        const errors = await validateForm(values);
        if (!Object.keys(errors).length) {
            await handleSearch(values);
        }
        await setSubmitting(false);
        if (lastActiveField === SEARCH_FIELD && inputRef) {
            try {
                inputRef.focus();
            } catch (e) {
                console.info('cannot focus on input');
            }
        }
    };

    function handleOpenAlert() {
        setOpenAlert(true);
    }

    function handleCloseAlert() {
        setOpenAlert(false);
        setAlertConfig({});
        handleMenuClose();
    }

    async function handleDeleteAlert() {
        const { id } = selectedCategory;
        handleSelectCategory(null, ALL_TEMPLATES_CATEGORY_MOCK);
        const deleted = await deleteCategory(id);
        if (deleted) {
            getCategories();
            handleCloseAlert();
            handleMenuClose();
        }
    }

    async function handleDeleteCategory(event) {
        if (event) {
            event.stopPropagation();
        }

        handleMenuClose();
        handleOpenAlert();
        setAlertConfig({
            alertTitle: locale.actionString(locale.DELETE, 'Category'),
            alertText: locale.Messages.DELETE_CATEGORY_ALERT(selectedCategory?.name),
            alertBtnText: locale.DELETE,
            alertBtnColor: 'primary',
            actionClick: handleDeleteAlert,
            cancelAction: handleCloseAlert,
        });
    }

    const handleSelectCategory = (event, category) => {
        if (event) {
            event.stopPropagation();
        }
        category &&
            onChange(category.id !== ALL_TEMPLATES_CATEGORY_MOCK.id ? [category.id] : []);
        setSelectedCategory(category);
    };

    function handleMenuOpen(event, category) {
        if (event) {
            event.stopPropagation();
        }
        setSelectedCategory(category);
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose(event) {
        if (event) {
            event.stopPropagation();
        }
        setAnchorEl(null);
    }

    const handleAddOrEditCategory = (event, type) => {
        if (event) {
            event.stopPropagation();
        }
        const isEdit = type === 'edit';
        const companies = companyNames?.data || [];
        openFormDialog({
            dialogTitle: isEdit ? locale.EDIT_CATEGORY : locale.NEW_CATEGORY,
            validationSchema,
            id: selectedCategory?.id,
            actionBtnText: locale.SAVE,
            fields: [
                {
                    id: 'name',
                    value: isEdit ? selectedCategory?.name : '',
                    label: locale.NAME,
                    type: TEXT_FIELD,
                },
                {
                    id: 'companyNames',
                    type: MULTIPLE_SELECT_FIELD,
                    label: locale.User.COMPANY,
                    options: buildOptions(companyNames.data, COMPANIES_OPTIONS, true),
                    selectedValues: [
                        ...(Array.isArray(selectedCategory?.companyNames)
                            ? selectedCategory?.companyNames
                            : companies),
                    ],
                    disabled: isEdit,
                },
            ],
            confirmAction: isEdit ? editCategory : handleAddNewCategory,
        });
        handleMenuClose();
    };

    return (
        !hidden && (
            <div
                className={clsx(
                    classes.categories,
                    filtersOpen && classes.filtersOpen,
                    noTemplates && classes.noTemplates,
                )}
            >
                <div className={classes.categoriesTop}>
                    <FormikWrapper
                        ref={form}
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={null}
                        onSubmit={submit}
                    >
                        {({ values, isSubmitting }) =>
                            Object.keys(values).length > 0 &&
                            values && (
                                <Form
                                    onKeyPress={onKeyPress}
                                    className={classes.searchForm}
                                    onChange={onInputChange}
                                >
                                    <FormikField
                                        innerRef={setSearchRef}
                                        margin="dense"
                                        key={SEARCH_FIELD}
                                        variant="outlined"
                                        label={locale.CATEGORY}
                                        type={SEARCH_FIELD}
                                        name={SEARCH_FIELD}
                                        className={clsx(
                                            classes.search,
                                            inputOpened && classes.open,
                                        )}
                                        disabled={isSubmitting}
                                        onToggleCallback={onInputToggle}
                                    />
                                </Form>
                            )
                        }
                    </FormikWrapper>
                    <Btn
                        className={clsx(classes.button, inputOpened && classes.hidden)}
                        text={locale.NEW_CATEGORY}
                        size="small"
                        variant="contained"
                        onClick={event => handleAddOrEditCategory(event, 'create')}
                        btnType="simpleBtn"
                        startIcon={<PlusIcon fontSize="inherit" />}
                    />
                </div>
                <Typography variant="body1" align="center" className={classes.title}>
                    {locale.CATEGORIES}
                </Typography>
                <div className={classes.content}>
                    <List className={classes.categoriesMenu}>
                        <ListItem
                            key={ALL_TEMPLATES_CATEGORY_MOCK.id}
                            className={clsx(
                                classes.navItem,
                                selectedCategory?.id === ALL_TEMPLATES_CATEGORY_MOCK.id &&
                                    classes.active,
                            )}
                            onClick={event =>
                                handleSelectCategory(event, ALL_TEMPLATES_CATEGORY_MOCK)
                            }
                            button
                            disableRipple
                        >
                            <ListItemText primary={ALL_TEMPLATES_CATEGORY_MOCK.name} />
                        </ListItem>
                        {Object.keys(categories) &&
                            categories &&
                            categories.map(category => (
                                <ListItem
                                    key={category.id}
                                    className={clsx(
                                        classes.navItem,
                                        selectedCategory?.id === category.id &&
                                            classes.active,
                                    )}
                                    onClick={event =>
                                        handleSelectCategory(event, category)
                                    }
                                    button
                                    disableRipple
                                >
                                    <ListItemText primary={category.name} />
                                    <IconButton
                                        dataid={category.id}
                                        className={classes.menuButton}
                                        aria-label="More"
                                        aria-controls="actions-menu"
                                        aria-haspopup="true"
                                        onClick={event => handleMenuOpen(event, category)}
                                        disabled={false}
                                    >
                                        <MoreVertIcon fontSize="inherit" />
                                    </IconButton>
                                </ListItem>
                            ))}
                        <Menu
                            id="actions-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleMenuClose}
                        >
                            <MenuItem
                                onClick={event => handleAddOrEditCategory(event, 'edit')}
                            >
                                {locale.EDIT_CATEGORY}
                            </MenuItem>
                            <MenuItem onClick={event => handleDeleteCategory(event)}>
                                {locale.DELETE}
                            </MenuItem>
                        </Menu>
                    </List>
                    {inputOpened && categories?.length === 0 && (
                        <ListItemText primary={locale.NO_RESULTS_FOUND} />
                    )}
                    {!!Object.keys(alertConfig).length && alertConfig && (
                        <Alert alertConfig={alertConfig} open={openAlert} />
                    )}
                    <Spinner loading={isLoading} opaque absolute small />
                </div>
            </div>
        )
    );
}

Categories.propTypes = {
    // onChange: func.isRequired,
    userId: string.isRequired,
    categories: arrayOf(Category),
    isLoading: bool.isRequired,
    getCategories: func.isRequired,
    createNewCategory: func.isRequired,
    editCategory: func.isRequired,
    deleteCategory: func.isRequired,
    openFormDialog: func.isRequired,
    filtersOpen: bool.isRequired,
    noTemplates: bool.isRequired,
    hidden: bool.isRequired,
};

export default withFormDialog(Categories);
