import React from 'react';
import { customFabric as fabric } from '../components/FabricComponents';
import FontFaceObserver from 'fontfaceobserver';
import TextFields from '@material-ui/icons/TextFields';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import FiberManualRecordOutlined from '@material-ui/icons/FiberManualRecordOutlined';
import {
    CANVAS_ADDITIONAL_PROPERTIES,
    CANVAS_TYPE_OBJECTS,
    Direction,
    NO_ROTATE_AND_SCALE_BOUND_POINTS,
    NO_ROTATE_BOUND_POINTS,
} from '../constants/canvas';
import { RectIcon, PlaceholderIcon } from '../constants/icons';
import { FONTS_MAP, textParamsSchema } from '../schemas/text-params';
import { convertToSeconds } from './common';
import { LIMIT_DURATION_VIDEO, CANVAS_GRID_SIZE } from '../constants/sizes';
import { locale } from '../constants/locales';
import {
    ANIMATION_DEFAULT_VALUES,
    ANIMATION_TYPE,
    APPEARANCE_TRANSITIONS_TYPE,
    DISAPPEARANCE_TRANSITIONS_TYPE,
} from '../constants/transitions';
import {
    convertAnimations,
    getAppearanceAnimations,
    getDisappearanceAnimations,
    isAppearanceAnimation,
} from './animations';
import { COLORS } from '../constants/colors';

/** * CANVAS ** */

export const isCanvas = element =>
    Object.prototype.hasOwnProperty.call(element, 'preserveObjectStacking');

export const isLine = element => element.type === CANVAS_TYPE_OBJECTS.line;
export const isRect = element => element.type === CANVAS_TYPE_OBJECTS.animatedRect;
export const isGroup = element => element.type === CANVAS_TYPE_OBJECTS.group;

export const setCanvasObjectParams = (activeObject, params) => {
    try {
        if (params && Object.keys(params).length) {
            activeObject.set(params);
        }
    } catch (e) {
        console.info('No active object provided');
    }
};

export const getObjectLabel = type => {
    switch (type) {
        case CANVAS_TYPE_OBJECTS.group:
            return { label: locale.OBJECTS.PLACEHOLDER, icon: <PlaceholderIcon /> };
        case CANVAS_TYPE_OBJECTS.animatedTextbox:
            return { label: locale.OBJECTS.TEXT, icon: <TextFields /> };
        case CANVAS_TYPE_OBJECTS.animatedRect:
            return { label: locale.OBJECTS.RECT, icon: <RectIcon /> };
        case CANVAS_TYPE_OBJECTS.videoImage:
            return { label: locale.OBJECTS.VIDEO, icon: null };
        case CANVAS_TYPE_OBJECTS.animatedImage:
            return { label: locale.OBJECTS.IMAGE, icon: null };
        case CANVAS_TYPE_OBJECTS.ellipse:
            return { label: locale.OBJECTS.ELLIPSE, icon: <FiberManualRecordOutlined /> };
        case CANVAS_TYPE_OBJECTS.line:
            return { label: locale.OBJECTS.LINE, icon: <LinearScaleIcon /> };
        default:
            return { label: locale.OBJECTS.PLACEHOLDER, icon: null };
    }
};

export const findCanvasItem = (property, canvas, findBy = 'objectId') => {
    let object = null;
    let index = -1;
    const objects = canvas.getObjects();
    const len = Object.keys(objects).length;
    for (let i = 0; i < len; i++) {
        if (objects[i][findBy] && objects[i][findBy] === property) {
            object = objects[i];
            index = i;
            break;
        }
    }

    return { object, index };
};

export const saveCanvasState = async (
    canvas,
    saveCanvasToHistory,
    canvasIndex,
    withHistoryUpdate = true,
    withPreview = false,
) => {
    if (canvas && typeof saveCanvasToHistory === 'function') {
        if (!canvas?.canvasWidth) {
            canvas.set('canvasInitialWidth', canvas.getWidth());
        }
        canvas.set('canvasCurrentWidth', canvas.getWidth());
        canvas.renderAll();
        await saveCanvasToHistory(
            canvas.toJSON(CANVAS_ADDITIONAL_PROPERTIES),
            canvasIndex,
            true,
            withHistoryUpdate,
            withPreview,
        );
    }
};

export const loadFonts = async (companyName, index) => {
    try {
        // console.info(`loading ${companyName} ${index} fonts...`);
        const promiseLoad = Object.values(textParamsSchema.CompanyFonts[companyName]).map(
            font => {
                const fontFamily = new FontFaceObserver(font);

                return fontFamily.load();
            },
        );
        if (index === 0) {
            promiseLoad.push(new FontFaceObserver('Material Icons').load());
        }
        return Promise.all(promiseLoad);
    } catch (e) {
        console.info(`Font loading failed`, e?.message);
    }
};

export const preventRotate = object => {
    object.setControlsVisibility(NO_ROTATE_BOUND_POINTS);
};

export const preventRotateAndScale = object => {
    object.setControlsVisibility(NO_ROTATE_AND_SCALE_BOUND_POINTS);
};

export const getCropBounds = target => {
    const obj = target.clipPath;
    const { top: imageTop, left: imageLeft } = target;
    const imageWidth = target.getScaledWidth();
    const imageHeight = target.getScaledHeight();
    // if you need margins set them here
    const margins = { top: 0, bottom: 0, left: 0, right: 0 };
    const topBound = obj.top - margins.top;
    const bottomBound = obj.top + obj.getScaledHeight() + margins.bottom;
    const leftBound = obj.left - margins.left;
    const rightBound = obj.left + obj.getScaledWidth() + margins.right;

    return {
        leftBound,
        rightBound,
        topBound,
        bottomBound,
        imageLeft,
        imageTop,
        imageWidth,
        imageHeight,
    };
};

export const adjustImageWithCrop = target => {
    try {
        if (
            target &&
            target?.clipPath?.type === CANVAS_TYPE_OBJECTS.animatedPlaceholder
        ) {
            adjustImageWithCropScaling(target);
            const {
                leftBound,
                rightBound,
                topBound,
                bottomBound,
                imageLeft,
                imageTop,
                imageWidth,
                imageHeight,
            } = getCropBounds(target);
            if (leftBound < imageLeft) {
                target.set('left', leftBound);
                target.setCoords();
            } else if (imageLeft + imageWidth < rightBound) {
                target.set('left', Math.max(imageLeft, rightBound - imageWidth));
                target.setCoords();
            }
            if (topBound < imageTop) {
                target.set('top', topBound);
                target.setCoords();
            } else if (imageTop + imageHeight < bottomBound) {
                target.set('top', Math.max(imageTop, bottomBound - imageHeight));
                target.setCoords();
            }
        }
    } catch (e) {
        console.info(e);
    }
};

export const adjustImageWithCropScaling = target => {
    try {
        if (
            target &&
            target?.clipPath?.type === CANVAS_TYPE_OBJECTS.animatedPlaceholder
        ) {
            const obj = target.clipPath;
            if (!target.minScaleLimit) {
                target.set(
                    'minScaleLimit',
                    Math.max(
                        obj.getScaledWidth() / target.width,
                        obj.getScaledHeight() / target.height,
                    ),
                );
                target.setCoords();
            }
            const {
                leftBound,
                rightBound,
                topBound,
                bottomBound,
                imageLeft,
                imageTop,
                imageWidth,
                imageHeight,
            } = getCropBounds(target);
            if (leftBound < imageLeft) {
                target.set('left', leftBound);
            }
            if (topBound < imageTop) {
                target.set('top', topBound);
                target.setCoords();
            }
            if (imageLeft + imageWidth < rightBound) {
                target.set('left', Math.max(imageLeft, rightBound - imageWidth));
                target.setCoords();
            }
            if (imageTop + imageHeight < bottomBound) {
                target.set('top', Math.max(imageTop, bottomBound - imageHeight));
                target.setCoords();
            }
        }
    } catch (e) {
        console.info(e);
    }
};

export const restoreLastActiveObject = (activeObject, canvas) => {
    if (activeObject) {
        const { object: currentActiveObject } = findCanvasItem(
            activeObject.objectId,
            canvas,
        );
        if (currentActiveObject) {
            canvas.setActiveObject(currentActiveObject);
        }
    }
};

export const getNewSlideDuration = ({ canvasData }) => {
    const longestVideoDuration = getLongestVideoDuration(canvasData.objects);
    const longestAnimationDuration = getLongestAnimationDuration(canvasData.objects);
    return Math.max(longestVideoDuration, longestAnimationDuration);
};

// Find the longest animation timeline
// appearance delay + appearance duration + disappearance delay + disappearance duration
export const getLongestAnimationDuration = objects =>
    objects.reduce((previousLongestAnimationTimeline, object) => {
        let longestAnimationTimeline = 0;
        const { animation: objectAnimations } = object;

        if (objectAnimations) {
            // Check appearance
            if (
                objectAnimations.playAppearanceAnimations &&
                Array.isArray(objectAnimations[ANIMATION_TYPE.APPEARANCE])
            ) {
                longestAnimationTimeline += objectAnimations[
                    ANIMATION_TYPE.APPEARANCE
                ].reduce(maxDuration, 0);
            }

            // Check disappearance
            if (
                objectAnimations.playDisappearanceAnimations &&
                Array.isArray(objectAnimations[ANIMATION_TYPE.DISAPPEARANCE])
            ) {
                longestAnimationTimeline += objectAnimations[
                    ANIMATION_TYPE.DISAPPEARANCE
                ].reduce(maxDuration, 0);
            }
        }
        return Math.max(longestAnimationTimeline, previousLongestAnimationTimeline);
    }, 0) / 1000;

export const getLongestVideoDuration = objects => {
    const videos = objects.filter(o =>
        Object.prototype.hasOwnProperty.call(o, 'videoSrc'),
    );
    let biggestDuration = 0;
    if (videos && videos.length) {
        for (const vid of videos) {
            const { videoDuration } = vid;
            // Check video duration
            const duration = convertToSeconds(videoDuration && videoDuration);
            if (duration > biggestDuration) {
                biggestDuration = duration;
            }
        }
    }

    return biggestDuration;
};

export const checkVideoDuration = (
    objects,
    showDurationNotifications,
    clearDurationNotifications,
    durationNotificationIsOn,
) => {
    const biggestDuration = getLongestVideoDuration(objects);
    if (biggestDuration) {
        if (biggestDuration > LIMIT_DURATION_VIDEO && !durationNotificationIsOn) {
            showDurationNotifications();
        }
        if (biggestDuration <= LIMIT_DURATION_VIDEO && !durationNotificationIsOn) {
            clearDurationNotifications();
        }
    } else if (durationNotificationIsOn) {
        clearDurationNotifications();
    }
};

export const validateFontForCanvas = (canvas, companyName) => {
    if (canvas && Object.keys(canvas).length) {
        const texts = canvas
            .getObjects()
            .filter(o => o.type === CANVAS_TYPE_OBJECTS.animatedTextbox);
        if (texts && texts.length) {
            for (const text of texts) {
                const { fontFamily } = text;
                const allowedFonts = textParamsSchema.CompanyFonts[companyName];
                if (!allowedFonts.includes(fontFamily)) {
                    let newFontFamily = allowedFonts[0];
                    // TODO add some mapper
                    // Backwards compatibility with old font names
                    if (fontFamily === FONTS_MAP.DEPRECATED.MUSEO) {
                        newFontFamily = FONTS_MAP.MUSEO_SANS[500];
                    } else if (fontFamily === FONTS_MAP.DEPRECATED.TRADE_GOTHIC) {
                        newFontFamily = FONTS_MAP.TRADE_GOTHIC[700];
                    } else if (fontFamily === FONTS_MAP.DEPRECATED.AVENIR) {
                        newFontFamily = FONTS_MAP.AVENIR[500];
                    } else if (fontFamily === FONTS_MAP.DEPRECATED.NOBEL) {
                        newFontFamily = FONTS_MAP.NOBEL[500];
                    }
                    fabric.util.clearFabricFontCache(newFontFamily);
                    text.initDimensions();
                    text.set({ fontFamily: newFontFamily });
                }
            }
        }
    }
};

export const handleTextChange = (target, errorNotification) => {
    if (target) {
        const { textTransform, maxCharacters, text } = target;
        // handle maxCharacters
        if (typeof maxCharacters === 'number' && maxCharacters > 0) {
            if (text.length > maxCharacters) {
                errorNotification({
                    message: `Maximum characters - ${maxCharacters}`,
                    data: { preventDuplicate: true },
                });
                const newText = text.slice(0, Number(maxCharacters));
                target.hiddenTextarea.value = newText;
                target.setSelectionStart(newText.length);
                target.setSelectionEnd(newText.length);
                target.set('text', newText);
            }
        }
        if (textTransform === textParamsSchema.Transform.uppercase) {
            target.set('text', target.get('text').toUpperCase());
        }
        if (textTransform === textParamsSchema.Transform.lowercase) {
            target.set('text', target.get('text').toLowerCase());
        }
    }
};
export const getCanvasGridCellSize = canvas => {
    const [scale = 1] = canvas.viewportTransform;
    const canvasScaledWidth = canvas.getWidth() / scale;
    const canvasScaledHeight = canvas.getHeight() / scale;
    const gridSize = canvas.gridSize || CANVAS_GRID_SIZE;

    return {
        canvasScaledWidth,
        canvasScaledHeight,
        cellX: canvasScaledWidth / gridSize,
        cellY:
            canvasScaledHeight /
            Math.ceil(canvasScaledHeight / (canvasScaledWidth / gridSize)),
    };
};

export const buildGrid = canvas => {
    const { canvasScaledWidth, canvasScaledHeight, cellX, cellY } = getCanvasGridCellSize(
        canvas,
    );
    const options = {
        distanceX: cellX,
        distanceY: cellY,
        width: canvasScaledWidth,
        height: canvasScaledHeight,
        param: {
            // ATTENTION - We don't need to add objectId here since we're using
            // its absense during canvas operations
            stroke: COLORS.secondary,
            strokeWidth: 1,
            objectCaching: false,
            evented: false,
            selectable: false,
        },
    };
    const gridWidth = Math.ceil(canvasScaledWidth / options.distanceX);
    const gridHeight = Math.ceil(canvasScaledHeight / options.distanceY);
    for (let i = 1; i < gridHeight; i++) {
        const distance = i * options.distanceY;
        const horizontal = new fabric.Line(
            [0, distance, options.width, distance],
            options.param,
        );
        if (i % (gridHeight * 0.5) === 0) {
            horizontal.set({ strokeWidth: 3 });
        }
        canvas.add(horizontal).sendToBack(horizontal);
    }
    for (let i = 1; i < gridWidth; i++) {
        const distance = i * options.distanceX;
        const vertical = new fabric.Line(
            [distance, 0, distance, options.height],
            options.param,
        );
        if (i % (gridWidth * 0.5) === 0) {
            vertical.set({ strokeWidth: 3 });
        }
        canvas.add(vertical).sendToBack(vertical);
    }
    canvas.set({ hasGrid: true });
    canvas.renderAll();
};

export const removeGrid = canvas => {
    canvas.forEachObject(o => {
        if (!o.objectId) {
            canvas.remove(o);
        }
    });
    canvas.renderAll();
};

export const Snap = (value, gridSize) => {
    return Math.round(value / gridSize) * gridSize;
};

export const snapMoving = (target, canvas, direction) => {
    const gridSize = canvas.gridSize || CANVAS_GRID_SIZE;
    const [scale = 1] = canvas.viewportTransform;
    const canvasScaledWidth = canvas.getWidth() / scale;
    const canvasScaledHeight = canvas.getHeight() / scale;
    const gridX = canvasScaledWidth / gridSize;
    const gridY =
        canvasScaledHeight /
        Math.ceil(canvasScaledHeight / (canvasScaledWidth / gridSize));
    if (direction) {
        if (direction === Direction.TOP || direction === Direction.BOTTOM) {
            checkArrowDirection(target, direction, gridY);
        } else if (direction === Direction.LEFT || direction === Direction.RIGHT) {
            checkArrowDirection(target, direction, gridX);
        }
    } else {
        target.set({
            left: Snap(target.left, gridX),
            top: Snap(target.top, gridY),
        });
    }
};

export const snapScaling = (event, canvas) => {
    const [scale = 1] = canvas.viewportTransform;
    const canvasScaledWidth = canvas.getWidth() / scale;
    const canvasScaledHeight = canvas.getHeight() / scale;
    const gridSize = canvas.gridSize || CANVAS_GRID_SIZE;
    const gridX = canvasScaledWidth / gridSize;
    const gridY =
        canvasScaledHeight /
        Math.ceil(canvasScaledHeight / (canvasScaledWidth / gridSize));
    const { transform } = event;
    const { target } = transform;
    const targetWidth = target.width * target.scaleX;
    const targetHeight = target.height * target.scaleY;

    const anchorX = transform.originX;
    const anchorY = transform.originY;
    //  Is uni scaling feature => anchorY === anchorX
    const isMedia =
        target.type === CANVAS_TYPE_OBJECTS.videoImage ||
        target.type === CANVAS_TYPE_OBJECTS.animatedImage;
    const isUniScaling = anchorY === anchorX;

    const snap = {
        width: isUniScaling ? Snap(targetWidth, gridX * 2) : Snap(targetWidth, gridX),
        height: isUniScaling ? Snap(targetHeight, gridY * 2) : Snap(targetHeight, gridY),
    };

    const dist = {
        // distance from current width to snappable width
        width: Math.abs(targetWidth - snap.width),
        height: Math.abs(targetHeight - snap.height),
    };
    const centerPoint = target.getCenterPoint();

    const anchorPoint = target.translateToOriginPoint(centerPoint, anchorX, anchorY);
    const attrs = {
        scaleX: target.scaleX,
        scaleY: target.scaleY,
    };
    // eslint-disable-next-line default-case
    switch (transform.corner) {
        case 'tl':
        case 'tr':
        case 'bl':
        case 'br':
            if (dist.width < gridX) {
                attrs.scaleX = snap.width / target.width;
            }
            if (dist.height < gridY) {
                if (isMedia) {
                    const aspectRatio = target.width / target.height;
                    attrs.scaleY = snap.width / aspectRatio / target.height;
                } else {
                    attrs.scaleY = snap.height / target.height;
                }
            }

            break;
        case 'mt':
        case 'mb':
            if (dist.height < gridSize) {
                attrs.scaleY = snap.height / target.height;
            }

            break;
        case 'ml':
        case 'mr':
            if (dist.width < gridSize) {
                if (target.type !== CANVAS_TYPE_OBJECTS.animatedTextbox) {
                    attrs.scaleX = snap.width / target.width;
                } else {
                    attrs.width = snap.width;
                }
            }

            break;
    }
    if (
        attrs.scaleX !== target.scaleX ||
        attrs.scaleY !== target.scaleY ||
        attrs.width !== target.width ||
        attrs.height !== target.height
    ) {
        target.set(attrs);
        target.setPositionByOrigin(anchorPoint, anchorX, anchorY);
    }
};

export const checkArrowDirection = (activeObject, direction, step) => {
    const { top, left } = activeObject;
    switch (direction) {
        case Direction.TOP:
            activeObject.set(Direction.TOP, Snap(top, step) - step);
            break;
        case Direction.BOTTOM:
            activeObject.set(Direction.TOP, Snap(top, step) + step);
            break;
        case Direction.LEFT:
            activeObject.set(Direction.LEFT, Snap(left, step) - step);
            break;
        case Direction.RIGHT:
            activeObject.set(Direction.LEFT, Snap(left, step) + step);
            break;
    }
};

export const setParamsPlaceholder = (object, params) => {
    object._objects.map(obj => {
        return obj.set(params);
    });
};

export const getAllObjectsInPoint = (canvas, point) => {
    const objects = [];
    canvas.forEachObject(o => {
        if (o.containsPoint(point)) {
            return objects.push(o);
        }
    });
    return objects;
};

export const findPlaceholder = (objects, point) => {
    let object;
    for (let i = 0; i < objects.length; i++) {
        const hasClipPath =
            objects[i].clipPath &&
            Object.keys(objects[i].clipPath).length &&
            objects[i].clipPath.type === CANVAS_TYPE_OBJECTS.animatedPlaceholder;
        const isGroup = objects[i].type === CANVAS_TYPE_OBJECTS.group;
        if (hasClipPath) {
            const { left, top, width: w, height: h } = objects[
                i
            ].clipPath?.getBoundingRect();
            const isPlaceholder =
                point.x >= left &&
                point.x <= left + w &&
                point.y >= top &&
                point.y <= top + h;
            if (isPlaceholder) {
                object = objects[i];
                break;
            }
        } else if (isGroup) {
            object = objects[i];
            break;
        }
    }
    return object;
};

// TODO Adapt this for TransitionsPanel also
export const zoomClippedImage = ({ object, canvas, duration, scaleFactor }) => {
    const { width, height } = object;
    const ratio = width / height;
    object.setCoords();
    object.animate('width', width * scaleFactor, {
        duration,
        from: width,
        onChange: val => {
            setCanvasObjectParams(object, {
                height: val / ratio,
                dirty: true,
            });
            if (canvas && Object.keys(canvas).length) {
                canvas.renderAll();
            }
        },
        onComplete: () => {
            if (canvas && Object.keys(canvas).length) {
                canvas.renderAll();
            }
        },
    });
};

export const mapWeightToFont = (weight, fontFamily) => FONTS_MAP[fontFamily][weight];

export const maxDuration = (a, b) => {
    return Math.max(+b.options.duration + b.options.delay, a);
};

export const maxDelay = (a, b) => {
    return Math.max(b.options.delay, a);
};

export const maxDurationOnly = (a, b) => {
    return Math.max(+b.options.duration, a);
};

export const prepareSelectOptionsFromAnimation = (animations, animationsType) => {
    const getValuesByGroup = group => {
        if (group === 'direction') {
            return ['top', 'bottom', 'left', 'right'];
        }
        return [group];
    };

    const defaultAvailableOptions =
        animationsType === ANIMATION_TYPE.APPEARANCE
            ? APPEARANCE_TRANSITIONS_TYPE
            : DISAPPEARANCE_TRANSITIONS_TYPE;

    const availableOptions = [defaultAvailableOptions, defaultAvailableOptions];
    if (animations.length === 2) {
        availableOptions[0] = defaultAvailableOptions.filter(
            type =>
                type.value === 'none' ||
                !getValuesByGroup(animations[1].group).includes(type.value),
        );
        availableOptions[1] = defaultAvailableOptions.filter(
            type =>
                type.value === 'none' ||
                !getValuesByGroup(animations[0].group).includes(type.value),
        );
    }

    return availableOptions;
};

export const addNewAnimation = (animations, animationsType) => {
    const getAnimations =
        animationsType === ANIMATION_TYPE.APPEARANCE
            ? getAppearanceAnimations
            : getDisappearanceAnimations;
    return {
        animations: [
            ...convertAnimations(getAnimations(animations)),
            ANIMATION_DEFAULT_VALUES,
        ],
    };
};

export const maxAppearanceDelay = animation => {
    let maxDelay = 0;

    if (animation) {
        if (isAppearanceAnimation(animation) && animation.playAppearanceAnimations) {
            maxDelay = getAppearanceAnimations(animation).reduce(
                (a, b) => Math.max(b.options.delay, a),
                0,
            );
        }
    }

    return maxDelay;
};

export const calculateZoomDuration = (
    totalDuration,
    maxAppearanceDelayTime,
    objectAnimations,
) => {
    let zoomDuration = totalDuration;

    if (objectAnimations) {
        const {
            appearance,
            playAppearanceAnimations,
            disappearance,
            playDisappearanceAnimations,
        } = objectAnimations;

        // Duration of animation which has the longest duration + delay
        let latestAppearanceAnimationDuration = 0;

        // Appearance
        if (playAppearanceAnimations && Array.isArray(appearance)) {
            // If there is only appearance animation then the duration of zoom is
            // total duration - maximum delay of appearance animations
            zoomDuration -= maxAppearanceDelayTime;

            let maxAppearance = 0;
            appearance.forEach(appearanceAnim => {
                const { duration, delay } = appearanceAnim.options || {
                    duration: 0,
                    delay: 0,
                };
                const appearanceAnimationTime = duration + delay;

                if (appearanceAnimationTime > maxAppearance) {
                    maxAppearance = appearanceAnimationTime;
                    latestAppearanceAnimationDuration = duration;
                }
            });
        }

        // Disappearance
        if (playDisappearanceAnimations && Array.isArray(disappearance)) {
            const maxDisappearanceDuration = disappearance.reduce(maxDuration, 0);
            // If there is disappearance animations then the duration of zoom should be
            // longest appearance animation duration + longest disappearance delay and duration
            zoomDuration = latestAppearanceAnimationDuration + maxDisappearanceDuration;
        }
    }

    return zoomDuration;
};
