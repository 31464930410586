import React from 'react';
import { generate } from 'shortid';
import { ROLES } from '../constants/roles';
import { COMPANY_LOGOS } from '../constants/companies';
import {
    DEFAULT_FILTERS,
    ROOT_PATHS,
    EDITOR_PATHS,
    OTHER_PATHS,
    DEFAULT_TRANSPARENCY,
} from '../constants/variables';
import { ALL_TEMPLATES_CATEGORY_MOCK } from '../constants/categories';
import { locale } from '../constants/locales';
import { getAlphaFromRGBA } from './helpers';

export const fetchTimeout = (url, options, timeout = 15000) => {
    return Promise.race([
        fetch(url, options),
        new Promise((_, reject) =>
            setTimeout(() => reject(new Error('Request timeout')), timeout),
        ),
    ]);
};
export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const isRootPath = location =>
    location &&
    (location.pathname === ROOT_PATHS.templates ||
        location.pathname === ROOT_PATHS.projects ||
        location.pathname === ROOT_PATHS.users ||
        location.pathname === ROOT_PATHS.system);

export const buildCompanyLogos = companies =>
    companies && companies.length > 1 ? (
        <div className="logoWrapper">
            <img src={COMPANY_LOGOS.ALL} alt="Simplicity" className="logo" />
        </div>
    ) : (
        companies.map(companyName => (
            <div className="logoWrapper" key={companyName}>
                <img
                    src={COMPANY_LOGOS[companyName]}
                    alt={companyName}
                    className="logo"
                />
            </div>
        ))
    );

export const uppercaseFirstLetter = str =>
    `${str[0].toUpperCase()}${str.slice(1).toLowerCase().replace(/_/g, ' ')}`;

export const buildRolesString = roles =>
    roles && roles.map(role => uppercaseFirstLetter(role)).join(', ');

export const getHeaderTitle = pathname => {
    if (pathname.includes(ROOT_PATHS.users)) {
        return locale.USERS_MANAGEMENT;
    } else if (pathname.includes(ROOT_PATHS.templates)) {
        return locale.TEMPLATES;
    } else if (pathname.includes(ROOT_PATHS.projects)) {
        return locale.PROJECTS;
    } else if (pathname.includes(ROOT_PATHS.system)) {
        return locale.System.SYSTEM_MANAGEMENT;
    } else if (pathname.includes(EDITOR_PATHS.profile)) {
        return locale.EDIT_USER_PROFILE;
    } else if (pathname.includes(OTHER_PATHS.userNotifications)) {
        return locale.NOTIFICATIONS;
    } else {
        return null;
    }
};
/**
 * @param withoutShowAll - When set false that to show option "show all", when set true don't show
 * @param items - list of items
 * @param options - list of options
 * */
export const buildOptions = (items = [], options = [], withoutShowAll = false) => {
    const cleanOptions = [];
    items.map(item => {
        const option = options.find(opt => opt.value === item);

        return option && cleanOptions.push(option);
    });
    if (items.length === options.length && !withoutShowAll) {
        cleanOptions.unshift(DEFAULT_FILTERS);
    }

    return cleanOptions;
};

export const buildCategoriesOptions = (categories, withDefault = false) => {
    const options = categories.map(c => ({ value: c.id, label: c.name }));
    if (withDefault) {
        options.unshift({
            value: ALL_TEMPLATES_CATEGORY_MOCK.id,
            label: ALL_TEMPLATES_CATEGORY_MOCK.name,
        });
    }
    return options;
};

export const getBaseRoute = roles => {
    let baseRoute = ROOT_PATHS.projects;
    if (roles) {
        if (roles.includes(ROLES.ADMIN)) {
            baseRoute = ROOT_PATHS.users;
        }
        if (roles.includes(ROLES.DESIGNER) && !roles.includes(ROLES.ADMIN)) {
            baseRoute = ROOT_PATHS.templates;
        }
    }

    return baseRoute;
};

function loadVideo(videoUrl, element, resolve) {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';

    xhr.onload = function () {
        const reader = new FileReader();

        reader.onloadend = function () {
            const byteCharacters = atob(
                reader.result.slice(reader.result.indexOf(',') + 1),
            );
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'video/mp4' });
            element.dataset.url = videoUrl + `?${element.id}`;
            element.src = URL.createObjectURL(blob);
            return resolve(element);
        };

        reader.readAsDataURL(xhr.response);
    };

    xhr.open('GET', videoUrl);
    xhr.send();
}
export const createElement = ({ height = 1280, width = 720, url, type, id }) => {
    return new Promise(resolve => {
        const element = document.createElement(type);
        element.id = id || generate();
        element.width = width;
        element.height = height;
        element.crossOrigin = 'Anonymous';
        if (type === 'video') {
            element.preload = 'auto';
            element.muted = true;
            element.controls = false;
            element.autoplay = false;
            element.loop = false;
            loadVideo(url, element, resolve);
        } else {
            element.src = url;
            element.onload = () => {
                resolve(element);
            };
        }
    });
};

export const parseValidationError = ({ message, error }) => {
    if (Array.isArray(message)) {
        const errorsArray = [];
        message &&
            message.forEach(err => {
                const errorMessage =
                    err &&
                    `${uppercaseFirstLetter(err.property)}: ` +
                        Object.values(err.constraints);
                errorMessage && errorsArray.push(errorMessage);
            });
        if (errorsArray.length) {
            return errorsArray.join(', ');
        }

        return errorsArray.join();
    }
    if (message) {
        return message;
    }

    return error;
};

export const validateRoute = path =>
    new Promise(resolve => {
        if (window.location && window.location.pathname !== path) {
            resolve(path);
        }
    });

export const roundNumber = (value, decimals) => {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
};

export const deUmlaut = value => {
    value = value.toLowerCase();
    value = value.replace(/ä/g, 'ae');
    value = value.replace(/ö/g, 'oe');
    value = value.replace(/ü/g, 'ue');
    value = value.replace(/ß/g, 'ss');
    value = value.replace(/ã/g, 'a');
    value = value.replace(/ÿ/g, 'y');
    value = value.replace(/ /g, '');
    value = value.replace(/,/g, '');
    value = value.replace(/\(/g, '');
    value = value.replace(/\)/g, '');

    return value;
};

export function formatTime(seconds) {
    const date = new Date(0);
    date.setHours(0, 0, seconds, 0);
    const mm = String(date.getMinutes());
    const ss = String(date.getUTCSeconds());

    return `${mm.padStart(2, '0')}:${ss.padStart(2, '0')}`;
}

export function convertToSeconds(date) {
    const d = new Date();
    try {
        const [minutes, seconds] = date.split(':');
        d.setMinutes(minutes); // you can pass Number or String, it doesn't really matter
        d.setSeconds(seconds);

        return d.getMinutes() * 60 + d.getSeconds();
    } catch (e) {
        return false;
    }
}

export function compareArrays(first, second) {
    if (!Array.isArray(first) || !Array.isArray(second)) {
        return false;
    }

    return first.length !== second.length ? false : first.every(e => second.includes(e));
}

export function removeUserLocalData() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user_id');
    localStorage.removeItem('socketId');
    localStorage.removeItem('projects_filters');
    localStorage.removeItem('templates_filters');
    localStorage.removeItem('users_filters');
}

export function storeFiltersToLocalStorage(path, values) {
    if (path) {
        localStorage.setItem(`${path}_filters`, JSON.stringify(values));
    }
}

export function getStoredFilters(path) {
    try {
        const storedFilters = localStorage.getItem(`${path}_filters`);
        if (storedFilters) {
            return JSON.parse(storedFilters);
        }
    } catch (e) {
        console.info(e);
        return {};
    }
}

export function renderValue(value) {
    return value.charAt(0) + value.slice(1).toLowerCase();
}

export const getTransparencyFromColor = color => {
    if (!color) return undefined;
    const alpha = getAlphaFromRGBA(color);
    return alpha !== 0 ? alpha || DEFAULT_TRANSPARENCY : 0;
};

export function valueToText(val) {
    return `${Math.round((val - 1) * 100)}%`;
}
