import React, { useRef, useEffect, useState } from 'react';
import { number, func, bool } from 'prop-types';
import debouncePromise from 'debounce-promise-with-cancel';
import { Form } from 'formik';
import Collapse from '../../components/Collapse';
import Grid from '../Grid';
import FormikWrapper from '../FormikWrapper';
import FormikField from '../FormikField';
import { usePrevious } from '../../utils/hooks';
import validationSchema from '../../schemas/validations/effects-validation';
import SlideObject from '../../types/Slide';
import {
	LOGO_POSITION_OPTIONS,
    TRANSITION_DURATION_OPTIONS,
    TRANSITION_DURATIONS,
    TRANSITION_NAMES,
    TRANSITION_NAMES_OPTIONS,
} from '../../constants/slides';
import {
    TEXT_FIELD,
    SELECT_FIELD,
    DISCRETE_SLIDER_FIELD,
    CHECKBOX_FIELD,
} from '../../constants/variables';
import { locale } from '../../constants/locales';
import {
    DISCRETE_ZOOM_SLIDER_MAX_VALUE,
    DISCRETE_ZOOM_SLIDER_MIN_VALUE,
    DISCRETE_ZOOM_SLIDER_STEP,
    MARKS_ZOOM_SLIDER,
} from '../../constants/transitions';
import { valueToText } from '../../utils/common';
import classes from './EffectsBlockForm.module.scss';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

const EffectsBlockForm = ({ slide, totalSlides, handleUpdate, disableSlideDuration }) => {
    const form = useRef(null);
    const prevSlideId = usePrevious(slide.id);
    const [initialValues, setValues] = useState({
        id: slide.id,
        transitionName: TRANSITION_NAMES_OPTIONS[0].value,
        transitionDuration: TRANSITION_DURATION_OPTIONS[0].value,
        duration: 3,
        fadeIn: false,
        fadeOut: false,
        zoomIn: false,
        zoomInRange: 1.05,
        logo: null,
		logoPosition: null,
    });
    const [expanded, setExpanded] = useState(false);
    const [logoExpanded, setLogoExpanded] = useState(false);
    const [logoCheckboxValue, setLogoCheckboxValue] = useState('white');
    const [isVideo, setIsVideo] = useState(false);
    const [transitionDurationOptions, setTransitionDurationOptions] = useState(
        TRANSITION_DURATION_OPTIONS,
    );

    useEffect(() => {
        if (
            (!prevSlideId && Object.keys(slide).length) ||
            (Object.keys(slide).length && prevSlideId && prevSlideId !== slide.id)
        ) {
            if (slide.logo) {
                setLogoCheckboxValue(slide.logo);
                setLogoExpanded(true);
            } else {
                setLogoCheckboxValue(slide.logo);
                setLogoExpanded(false);
            }

            setExpanded(slide.zoomIn);
            setValues({
                id: slide.id,
                transitionName: slide.transitionName,
                transitionDuration: slide.transitionDuration,
                duration: slide.duration,
                fadeIn: slide.fadeIn,
                fadeOut: slide.fadeOut,
                zoomIn: slide.zoomIn,
                zoomInRange: slide.zoomInRange,
                logo: slide.logo,
				logoPosition: slide.logoPosition,
            });
        }
        if (slide?.imageUrl) {
            const videoFile = slide.imageUrl?.indexOf('.mp4') >= 0;
            setIsVideo(videoFile);
        }
    }, [slide, setExpanded, prevSlideId]);

    const setPropertiesDebounced = debouncePromise(handleUpdate, 400);

    const toggleCheckbox = async ({ target }) => {
        try {
            const { setFieldValue } = form?.current;
            if (target.name) {
                setFieldValue(target.name, target.checked, true);
                setExpanded(target.checked);
            }
        } catch (e) {
            console.info("can't update object", e);
        }
    };

    const toggleLogoCheckbox = async ({ target }) => {
        try {
            const { setFieldValue } = form?.current;
            if (target.name) {
                setFieldValue(target.name, target.checked, true);
                setLogoExpanded(target.checked);
                if (target.checked) {
                    await handleLogoChange({ target: { value: 'white' } });
					setFieldValue('logoPosition', 'bottom');
                } else {
                    await handleLogoChange({ target: { value: null } });
                }
            }
        } catch (e) {
            console.info("can't update object", e);
        }
    };

	const changeLogoPosition = async ({ logoPosition }) => {
		const { setFieldValue } = form.current;
		setFieldValue('logoPosition', logoPosition);
		await change();
	};

    const handleLogoChange = async ({ target }) => {
        const { setFieldValue } = form?.current;
        setLogoCheckboxValue(target.value);
        setFieldValue('logo', target.value);
    };

    const submit = async (values, submitProps) => {
        const { setSubmitting, validateForm } = submitProps;
        const errors = await validateForm(values);
        if (slide && !Object.keys(errors).length) {
            await setPropertiesDebounced({
                ...values,
                id: slide.id,
            });
        }
        await setSubmitting(false);
    };

    // Trigger validation and submission
    const change = async () => {
        try {
            setPropertiesDebounced.cancel();
            await form?.current?.validateForm?.();
            await form?.current?.handleSubmit?.();
        } catch (e) {
            console.info(e.message);
        }
    };

    const changeTransitionType = async slide => {
        // Enable 0 duration only for TRANSITION_DURATIONS.None
        if (slide.transitionName === TRANSITION_NAMES.None) {
            setTransitionDurationOptions([
                ...TRANSITION_DURATION_OPTIONS,
                { value: TRANSITION_DURATIONS.None, label: TRANSITION_DURATIONS.None },
            ]);
            form?.current?.setFieldValue('transitionDuration', TRANSITION_DURATIONS.None);
        } else {
            setTransitionDurationOptions(TRANSITION_DURATION_OPTIONS);
            const value =
                slide.transitionDuration === TRANSITION_DURATIONS.None
                    ? TRANSITION_DURATION_OPTIONS[0].value
                    : slide.transitionDuration;
            form?.current?.setFieldValue('transitionDuration', value);
        }
        await change();
    };

    return (
        <FormikWrapper
            ref={form}
            initialValues={initialValues}
            noValidate
            validationSchema={validationSchema ?? null}
            enableReinitialize
            onSubmit={submit}
        >
            {({ values, handleSubmit }) =>
                Object.keys(values).length && (
                    <Form
                        className={classes.EffectsBlockForm}
                        onSubmit={handleSubmit}
                        onChange={change}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <FormikField
                                    name="duration"
                                    label="Slide duration (seconds)"
                                    type={TEXT_FIELD}
                                    fullWidth
                                    disabled={disableSlideDuration}
                                />
                                {/* Do not show transitions for first slide */}
                                {slide.index > 0 && (
                                    <FormikField
                                        select
                                        type={SELECT_FIELD}
                                        name="transitionName"
                                        label="Transition type"
                                        fullWidth
                                        options={TRANSITION_NAMES_OPTIONS}
                                        onChangeCallback={changeTransitionType}
                                    />
                                )}
                                {/* Do not show transitions for first slide */}
                                {slide.index > 0 &&
                                    slide.transitionName !== TRANSITION_NAMES.None && (
                                        <FormikField
                                            select
                                            type={SELECT_FIELD}
                                            name="transitionDuration"
                                            label="Transition duration (seconds)"
                                            fullWidth
                                            options={transitionDurationOptions}
                                            onChangeCallback={change}
                                        />
                                    )}
                                {/* Show fadein/out options for FIRST and LAST slides */}
                                {slide.index === 0 && (
                                    <FormikField
                                        fullWidth
                                        className={classes.formLabel}
                                        classCheckBox={classes.checkBox}
                                        type={CHECKBOX_FIELD}
                                        name="fadeIn"
                                        color="primary"
                                        label="Slide fade-in effect"
                                        labelPlacement="start"
                                    />
                                )}
                                {slide.index === totalSlides && (
                                    <FormikField
                                        fullWidth
                                        className={classes.formLabel}
                                        classCheckBox={classes.checkBox}
                                        type={CHECKBOX_FIELD}
                                        name="fadeOut"
                                        color="primary"
                                        label="Slide fade-out effect"
                                        labelPlacement="start"
                                    />
                                )}
                                {!isVideo && (
                                    <Collapse
                                        expanded={expanded}
                                        checkbox
                                        label={locale.SCALE_UP}
                                        checkboxColor="secondary"
                                        onChange={toggleCheckbox}
                                        timeout={0}
                                        labelPlacement="start"
                                        fullWidth
                                        name="zoomIn"
                                    >
                                        <div className={classes.collapse}>
                                            <FormikField
                                                type={DISCRETE_SLIDER_FIELD}
                                                name="zoomInRange"
                                                onChangeCallback={change}
                                                valueLabelDisplay="auto"
                                                minValue={DISCRETE_ZOOM_SLIDER_MIN_VALUE}
                                                maxValue={DISCRETE_ZOOM_SLIDER_MAX_VALUE}
                                                step={DISCRETE_ZOOM_SLIDER_STEP}
                                                valueLabelFormat={valueToText}
                                                getAriaValueText={valueToText}
                                                marks={MARKS_ZOOM_SLIDER}
                                            />
                                        </div>
                                    </Collapse>
                                )}
                                <Collapse
                                    expanded={logoExpanded}
                                    checkbox
                                    label={locale.ADD_LOGO}
                                    checkboxColor="secondary"
                                    onChange={toggleLogoCheckbox}
                                    timeout={0}
                                    labelPlacement="start"
                                    fullWidth
                                    name="addLogo"
                                >
                                    <div className={classes.collapse}>
                                        <RadioGroup
                                            value={logoCheckboxValue}
                                            onChange={handleLogoChange}
                                        >
                                            <FormControlLabel
                                                value="white"
                                                control={<Radio />}
                                                label={locale.WHITE_LOGO}
                                            />
                                            <FormControlLabel
                                                value="black"
                                                control={<Radio />}
                                                label={locale.BLACK_LOGO}
                                            />
                                        </RadioGroup>
										<FormikField
											select
											type={SELECT_FIELD}
											name="logoPosition"
											label="Logo Position"
											fullWidth
											options={LOGO_POSITION_OPTIONS}
											value={slide.logoPosition || 'bottom'}
											onChangeCallback={changeLogoPosition}
										/>
                                    </div>
                                </Collapse>
                            </Grid>
                        </Grid>
                    </Form>
                )
            }
        </FormikWrapper>
    );
};

EffectsBlockForm.propTypes = {
    handleUpdate: func.isRequired,
    slide: SlideObject.isRequired,
    totalSlides: number.isRequired,
    disableSlideDuration: bool.isRequired,
};

export default EffectsBlockForm;
