import React, { useCallback, useEffect, useState } from 'react';
import { func, object, oneOf, string } from 'prop-types';
import Alert from '../Alert';
import { locale } from '../../constants/locales';
import { EDITOR_TYPE } from '../../constants/item';
import { useUnmount, useMount } from '../../utils/hooks';
import { ROOT_PATHS } from '../../constants/variables';
import User from '../../types/User';

const ItemLockedWarning = ({
    go,
    itemId,
    authUser,
    lockItem,
    editorType,
    continueEdit,
    finishEditing,
    isStillEditing,
    unlockItemAndFetchData,
    clearFinishEditingMessage,
}) => {
    const [showPopup, setShowPopup] = useState(false);
    const [item, setItem] = useState({});

    const handleContinueEditing = () => {
        setShowPopup(false);
        continueEdit(item);
    };

    const navigateOnFinish = useCallback(() => {
        setShowPopup(false);
        const isProjectEditor = editorType === EDITOR_TYPE.PROJECT;
        const urlToNavigate = isProjectEditor
            ? ROOT_PATHS.projects
            : ROOT_PATHS.templates;
        go(urlToNavigate);
    }, [go, setShowPopup, editorType]);

    const isStillObjectsLength = Object.keys(isStillEditing).length;
    const isFinishObjectsLength = Object.keys(finishEditing).length;

    useEffect(() => {
        try {
            if (isStillEditing[itemId]) {
                setItem(isStillEditing[itemId]);
                if (editorType === isStillEditing[itemId].itemType) {
                    if (showPopup) {
                        return;
                    }
                    setShowPopup(true);
                }
            }
        } catch (e) {
            console.info(e);
        }
    }, [
        isStillObjectsLength,
        isStillEditing,
        editorType,
        showPopup,
        setShowPopup,
        itemId,
    ]);

    useEffect(() => {
        try {
            if (finishEditing[itemId]) {
                if (editorType === finishEditing[itemId].itemType) {
                    clearFinishEditingMessage(finishEditing[itemId]);
                    navigateOnFinish();
                }
            }
        } catch (e) {
            console.info(e);
        }
    }, [
        isFinishObjectsLength,
        clearFinishEditingMessage,
        editorType,
        finishEditing,
        itemId,
        navigateOnFinish,
    ]);

    const handleFinishEditing = () => {
        setShowPopup(false);
        unlockItemAndFetchData(itemId, editorType, authUser?.companyNames);
    };

    useMount(() => lockItem(itemId, editorType));

    useUnmount(handleFinishEditing);

    return (
        <Alert
            alertConfig={{
                alertTitle: 'Still active?',
                alertText:
                    'You haven’t made any changes for a while. Are you still working on this?',
                alertBtnText: locale.YES,
                alertCancelBtnText: locale.NO,
                disableBackdropClick: true,
                cancelAction: navigateOnFinish,
                actionClick: handleContinueEditing,
                backdropClickAction: handleContinueEditing,
            }}
            open={showPopup}
        />
    );
};

ItemLockedWarning.propTypes = {
    go: func.isRequired,
    itemId: string,
    authUser: User,
    lockItem: func.isRequired,
    editorType: oneOf([EDITOR_TYPE.PROJECT, EDITOR_TYPE.TEMPLATE]),
    continueEdit: func.isRequired,
    finishEditing: object,
    isStillEditing: object,
    unlockItemAndFetchData: func.isRequired,
    clearFinishEditingMessage: func.isRequired,
};

export default ItemLockedWarning;
