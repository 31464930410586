import React, { useState, useRef, useEffect } from 'react';
import { bool, object } from 'prop-types';
import Collapse from '../../../components/Collapse';
import { WithCanvasContext } from '../../../utils/context';
import { locale } from '../../../constants/locales';
import { CANVAS_TYPE_OBJECTS } from '../../../constants/canvas';
import classes from './AnimationsPanel.module.scss';
import AppearanceAnimationsPanel from './AppearanceAnimationPanel/AppearanceAnimationsPanel';
import DisappearanceAnimationsPanel from './DisappearanceAnimationPanel/DisappearanceAnimationsPanel';
import {
    isAppearanceAnimation,
    isDisappearanceAnimation,
} from '../../../utils/animations';

function AnimationsPanel(props) {
    const { activeObject, disabled } = props;
    const isMounted = useRef(true);
    const [expanded, setExpanded] = useState(false);

    const handleCheckbox = async event => {
        const { checked } = event.target;
        if (!checked) {
            if (isMounted.current) {
                setExpanded(checked);
            }
        }
        setExpanded(checked);
    };

    useEffect(() => {
        if (activeObject) {
            const { animation } = activeObject;

            const isAppearance = !!(
                isAppearanceAnimation(animation) && animation?.playAppearanceAnimations
            );

            const isDisappearance = !!(
                isDisappearanceAnimation(animation) &&
                animation?.playDisappearanceAnimations
            );

            setExpanded(isAppearance || isDisappearance);
        } else {
            setExpanded(false);
        }
    }, [activeObject]);

    return (
        activeObject &&
        activeObject.type !== CANVAS_TYPE_OBJECTS.activeSelection && (
            <div className={`${classes.animationsPanel} boxBorder`}>
                <Collapse
                    expanded={expanded}
                    collapseBtn
                    checkboxColor="secondary"
                    label={locale.ANIMATION}
                    onChange={handleCheckbox}
                    timeout={0}
                    disabled={disabled}
                    labelPlacement="start"
                >
                    <AppearanceAnimationsPanel {...props} />
                    <DisappearanceAnimationsPanel {...props} />
                </Collapse>
            </div>
        )
    );
}

AnimationsPanel.propTypes = {
    activeObject: object,
    disabled: bool,
};

export default WithCanvasContext(AnimationsPanel);
