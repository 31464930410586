import { isEmpty } from 'underscore';
import { bindActionCreators } from 'redux';
import debouncePromise from 'debounce-promise-with-cancel';
import { environment } from '../../../environments/environment';
import queryString from 'query-string';
import { go } from '../../actions/navigation';
import {
    parseValidationError,
    deUmlaut,
    removeUserLocalData,
    fetchTimeout,
} from '../common';
import { JWT_ERROR } from '../../constants/errors';
import { locale } from '../../constants/locales';
import persistStore from '../../store/configureStore';
import { LOGOUT } from '../../actions/types';
import { UNAUTH_PATHS } from '../../constants/variables';

const resetAuthTries = async () => {};
const resetAuthTriesDebounced = debouncePromise(resetAuthTries, 10000);

const { store, persistor } = persistStore();

// Need to be here since of circular import issue
export const logout = () => ({
    type: LOGOUT,
});
const boundActions = bindActionCreators({ go, logout }, store.dispatch);

export const baseUrl = environment.apiUrl || 'https://api.dimamotion-dev.de';

export const opts = {
    mode: 'cors',
    cache: 'no-cache',
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Request-Headers': 'Content-Type',
    },
};

export const fetchError = async error => {
    const cleanUp = async () => {
        const accessToken = localStorage.getItem('access_token');
        if (accessToken) {
            alert(locale.Messages.AUTH_PROBLEMS);
        }
        removeUserLocalData();
        if (persistor) {
            await persistor.purge();
            await persistor.persist();
        }
        boundActions.logout();
        boundActions.go(UNAUTH_PATHS.login);
    };
    // Handle server down
    const serverDown = error && locale.NETWORK_ERRORS.includes(error.message);
    // Catch user with reset password and pass it back to auth login action
    if (error.status === 404 && error.statusCode === 204) {
        return error;
    }
    // Redirect to main page
    if (error.statusCode === 401 || error.statusCode === 403 || serverDown) {
        // Handle case on wrong access token from other envs
        // during local development
        if (error.statusCode === 401) {
            resetAuthTriesDebounced.cancel();
            let authRetries = localStorage.getItem('auth_retries') || 0;
            if (authRetries >= 5) {
                await cleanUp();
                localStorage.setItem('auth_retries', 0);
                return null;
            } else {
                localStorage.setItem('auth_retries', ++authRetries);
                await resetAuthTriesDebounced();
            }
        }
        if (error.message === JWT_ERROR || serverDown) {
            if (error.message === JWT_ERROR) {
                await cleanUp();
            }
        }
    }
    // If user is logged in and request is "Unathorized" - redirect user back
    const { auth } = store.getState();
    if (
        error.statusCode === 401 &&
        !serverDown &&
        auth &&
        Object.keys(auth.user).length
    ) {
        boundActions.go(UNAUTH_PATHS.login);
    }
    const errorText = parseValidationError(error);
    throw new Error(errorText);
};

const qs = params =>
    !isEmpty(params) ? `?${queryString.stringify(params, { arrayFormat: 'index' })}` : '';

const processResponse = async resp => {
    // Catch "too many requests" error
    if (resp.status === 429) {
        return fetchError(new Error(resp.statusText));
    }
    if (resp.message) {
        return { message: resp.message };
    }
    try {
        const body = await resp.json();

        return resp.ok ? body : fetchError({ ...body, status: resp.status });
    } catch (e) {
        if (e && e.message) {
            throw new Error(e.message);
        }
        throw new Error(`${resp.status}: ${resp.statusText}`);
    }
};

const getOptions = async (url, additionalHeaders) => {
    const accessToken = localStorage.getItem('access_token');
    // *** AUTH GUARD ***
    // Omit check for Login and setPassword pages
    const notLoginPath =
        url && !['/auth/login', '/auth/setPassword'].includes(url) && !accessToken;
    if (notLoginPath) {
        if (persistor) {
            await persistor.purge();
            await persistor.persist();
        }
        removeUserLocalData();
        await boundActions.logout();
        await boundActions.go(UNAUTH_PATHS.login);
        return null;
    }
    const options = { ...opts };
    options.headers = {
        ...options.headers,
        ...{
            Authorization: accessToken ? `Bearer ${accessToken}` : null,
        },
        ...additionalHeaders,
    };

    return options;
};

export const get = async (url, params = {}) => {
    const options = await getOptions(url, {});
    // Prevent Unauthorized requests
    if (!options) {
        return [];
    }
    const response = await fetchTimeout(`${baseUrl}${url}${qs(params)}`, {
        ...options,
        method: 'GET',
    }).catch(fetchError);

    return processResponse(response);
};

export const post = async (url, body = {}, customUrl = false) => {
    const options = await getOptions(url, {});
    if (!options) {
        return [];
    }
    const response = await fetchTimeout(customUrl ? url : `${baseUrl}${url}`, {
        ...options,
        method: 'POST',
        body: JSON.stringify(body),
    }).catch(fetchError);

    return processResponse(response);
};

export const put = async (url, body = {}) => {
    const options = await getOptions(url, {});
    if (!options) {
        return [];
    }
    const response = await fetchTimeout(`${baseUrl}${url}`, {
        ...options,
        method: 'PUT',
        body: JSON.stringify(body),
    }).catch(fetchError);

    return processResponse(response);
};

export const del = async (url, body = {}) => {
    const options = await getOptions(url, {});
    if (!options) {
        return {};
    }
    const response = await fetchTimeout(`${baseUrl}${url}`, {
        ...options,
        method: 'DELETE',
        body: JSON.stringify(body),
    }).catch(fetchError);

    return processResponse(response);
};

export const postFile = async (url, file = {}) => {
    const formData = new FormData();
    const name = deUmlaut(file.name);
    formData.append('asset', file, name);
    const options = await getOptions(url, {});
    // THIS IS NEEDED!!!
    delete options.headers['Content-Type'];

    const response = await fetchTimeout(
        `${baseUrl}${url}`,
        {
            ...options,
            method: 'POST',
            body: formData,
        },
        600000,
    ).catch(fetchError);

    return processResponse(response);
};
