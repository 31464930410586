import React, { useState, useEffect } from 'react';
import { array, object } from 'prop-types';
import Badge from '@material-ui/core/Badge';
import IconNotifications from '@material-ui/icons/Notifications';
import Btn from '../../components/Button';
import Popup from '../../components/Popup';
import NotificationsList from '../NotificationsList';
import classes from './UserNotificationsList.module.scss';

const UserNotificationsList = props => {
    const { userNotifications, location, popupClose, authUser } = props;
    const [isOpen, toggleOpen] = useState(false);
    const unreadNotifications = userNotifications.filter(
        i => !i?.readBy.includes(authUser.id),
    );

    useEffect(() => {
        if (unreadNotifications.length > 0) {
            // reset isOpen on new notifications
            toggleOpen(false);
        }
    }, [unreadNotifications.length]);

    function setOpenOnce() {
        if (!isOpen) {
            toggleOpen(true);
        }
    }

    const showAll = (
        <div className={classes.wrapperShowAll}>
            <div className={classes.showAll}>
                <div className={classes.notificationsBtn}>
                    <Btn
                        to="/user-notifications-history"
                        location={location}
                        onClick={() => popupClose()}
                    >
                        Show all
                    </Btn>
                </div>
            </div>
        </div>
    );

    return (
        <div className={classes.userNotificationsList}>
            <Popup
                iconButton={
                    <Badge
                        badgeContent={unreadNotifications.length}
                        invisible={isOpen || !unreadNotifications.length}
                        overlap="rectangle"
                        max={99}
                        classes={{
                            badge: classes.badge,
                            anchorOriginTopRightRectangle:
                                classes.anchorOriginTopRightRectangle,
                        }}
                    >
                        <IconNotifications />
                    </Badge>
                }
                setOpenOnce={setOpenOnce}
                menuLeft
            >
                <NotificationsList
                    list={userNotifications}
                    unreadNotifications={unreadNotifications}
                    popupList
                    location={location}
                />
                {showAll}
            </Popup>
        </div>
    );
};

UserNotificationsList.propTypes = {
    userNotifications: array,
    location: object,
};
export default UserNotificationsList;
