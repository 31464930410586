import React, { useState, useRef, useEffect } from 'react';
import { func, object, number, bool } from 'prop-types';
import debouncePromise from 'debounce-promise-with-cancel';
import ColorPicker from '../MaterialColorPicker';
import Collapse from '../Collapse';
import { WithCanvasContext } from '../../utils/context';
import { saveCanvasState } from '../../utils/canvas';
import { useMount } from '../../utils/hooks';
import { CANVAS_ALLOWED_BORDER_COLORED_OBJECTS } from '../../constants/canvas';
import { locale } from '../../constants/locales';
import {
    DEFAULT_BORDER_COLOR,
    DEFAULT_TRANSPARENCY,
    TEXT_FIELD,
} from '../../constants/variables';
import { Form } from 'formik';
import validationSchema from '../../schemas/validations/border-width-validation';
import FormikField from '../FormikField';
import FormikWrapper from '../FormikWrapper/FormikWrapper';
import { getTransparencyFromColor } from '../../utils/common';
import classes from './BorderColorPicker.module.scss';
import { getTypeColorPicker } from '../../constants/color-pickers';

function BorderColorPickerWrapper({
    canvas,
    activeObject,
    saveCanvasToHistory,
    canvasIndex,
    showEyedropper,
    toggleEyeDropper,
    disabled,
}) {
    const initialValues = {
        width: activeObject?.strokeWidth || activeObject?.savedStrokeWidth || 1,
        stroke: activeObject?.stroke,
    };
    const form = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const [border, setBorder] = useState(DEFAULT_BORDER_COLOR);
    const [transparency, setTransparency] = useState(DEFAULT_TRANSPARENCY);

    useMount(() => {
        try {
            if (activeObject) {
                const { stroke, strokeWidth } = getElement();
                setBorder(stroke);
                setTransparency(getTransparencyFromColor(stroke));
                setExpanded(!!strokeWidth);
            }
        } catch (e) {
            console.info('Cannot find active element');
        }
    });

    useEffect(() => {
        try {
            if (activeObject) {
                const { stroke, strokeWidth, savedStrokeWidth } = activeObject;
                stroke && setBorder(stroke);
                stroke && setTransparency(getTransparencyFromColor(stroke));
                if (form?.current) {
                    const { setFieldValue } = form.current;
                    setFieldValue('width', strokeWidth || savedStrokeWidth, true);
                }
            }
        } catch (e) {
            console.info('Cannot find active element', e);
        }
    }, [activeObject, setBorder]);

    const getElement = () => activeObject || canvas.getActiveObject();

    const setProperties = async newBorderColor => {
        try {
            const el = getElement();
            el.set({ stroke: newBorderColor });
            canvas.renderAll();
            await saveCanvasState(canvas, saveCanvasToHistory);
        } catch (e) {
            console.info('Cannot find active element');
        }
    };

    const setBorderWidthToProperties = async (newBorderWidth, withSave = false) => {
        try {
            const el = getElement();
            el.set('strokeWidth', newBorderWidth);
            if (withSave) {
                el.set('savedStrokeWidth', newBorderWidth);
            }
            canvas.renderAll();
            await saveCanvasState(canvas, saveCanvasToHistory);
        } catch (e) {
            console.info('Cannot find active element');
        }
    };

    const handleBorderColorChange = newValues => {
        if (typeof newValues === 'string') {
            setBorder(newValues);
            setTransparency(getTransparencyFromColor(newValues));
        } else {
            const { value: newBorderColor } = newValues;
            setBorder(newBorderColor);
            setTransparency(getTransparencyFromColor(newBorderColor));
        }
    };

    const handleBorderColorChangeComplete = async newValues => {
        if (typeof newValues === 'string') {
            await setProperties(newValues);
        } else {
            const { value: newColor } = newValues;
            await setProperties(newColor);
        }
    };

    const handleCheckbox = event => {
        const { checked } = event.target;
        setExpanded(checked);
        setBorderWidthToProperties(checked ? getElement().savedStrokeWidth : 0);
        canvas.renderAll();
    };

    // Handle checkbox on undo/redo
    useEffect(() => {
        try {
            if (activeObject) {
                const { strokeWidth, savedStrokeWidth } = activeObject;
                if (form?.current) {
                    const { setFieldValue } = form.current;
                    setFieldValue('width', strokeWidth || savedStrokeWidth, true);
                }
                setExpanded(!!strokeWidth);
            }
        } catch (e) {
            console.info('Cannot find active element');
        }
    }, [activeObject, canvasIndex]);

    // Add debounce for form values changes
    const setPropertiesDebounced = debouncePromise(
        ({ width }) => Promise.resolve(setBorderWidthToProperties(width, true)),
        400,
    );

    const submit = async (values, submitProps) => {
        const { setSubmitting, validateForm } = submitProps;
        const errors = await validateForm(values);
        if (!Object.keys(errors).length) {
            await setPropertiesDebounced(values);
        }
        await setSubmitting(false);
    };

    // Trigger submission
    const change = async () => {
        try {
            setPropertiesDebounced.cancel();
            await form?.current?.validateForm?.();
            await form?.current?.handleSubmit?.();
        } catch (e) {
            console.info(e);
        }
    };

    return (
        activeObject &&
        CANVAS_ALLOWED_BORDER_COLORED_OBJECTS.includes(activeObject.type) && (
            <div className="boxBorder">
                <Collapse
                    expanded={expanded}
                    checkbox
                    checkboxColor="secondary"
                    label={locale.BORDER}
                    onChange={handleCheckbox}
                    disabled={disabled}
                >
                    <div className={classes.borderColorPickerWrapper}>
                        <ColorPicker
                            label={locale.COLOR}
                            name={getTypeColorPicker(activeObject.type, 'stroke')}
                            value={border}
                            TextFieldProps={{
                                id: getTypeColorPicker(activeObject.type, 'stroke'),
                                placeholder: 'Color',
                            }}
                            showEyedropper={showEyedropper}
                            toggleEyeDropper={toggleEyeDropper}
                            transparency={transparency}
                            transparencyPosition="left"
                            className="formControl"
                            onChange={handleBorderColorChange}
                            onChangeComplete={handleBorderColorChangeComplete}
                            disabled={disabled}
                        />
                        <div className={classes.formWrapper}>
                            <FormikWrapper
                                ref={form}
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                enableReinitialize
                                onSubmit={submit}
                            >
                                {({ values, handleSubmit }) =>
                                    Object.keys(values).length && (
                                        <Form onSubmit={handleSubmit} onChange={change}>
                                            <FormikField
                                                label={locale.BORDER_WIDTH}
                                                type={TEXT_FIELD}
                                                name="width"
                                                fullWidth
                                                disabled={disabled}
                                                inputProps={{
                                                    autoComplete: 'off',
                                                }}
                                                className={classes.borderWidth}
                                            />
                                        </Form>
                                    )
                                }
                            </FormikWrapper>
                        </div>
                    </div>
                </Collapse>
            </div>
        )
    );
}

BorderColorPickerWrapper.propTypes = {
    canvas: object,
    activeObject: object,
    disabled: bool,
    showEyedropper: bool,
    canvasIndex: number.isRequired,
    saveCanvasToHistory: func.isRequired,
};
export default WithCanvasContext(BorderColorPickerWrapper);
