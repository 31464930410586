import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { arrayOf, func, object, string } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import Btn from '../../../components/Button';
import Alert from '../../../components/Alert';
import CategoriesPicker from '../../../components/CategoriesPicker';
import VideoDurationAlertIcon from '../../../components/VideoDurationAlertIcon';
import { useMount, usePrevious, useUnmount } from '../../../utils/hooks';
import SelectTemplate from '../SelectTemplate';
import Slide from '../../../components/Slide/Slide';
import { locale } from '../../../constants/locales';
import SlideObject from '../../../types/Slide';
import Category from '../../../types/Category';
import Template from '../../../types/Template';
import classes from './TemplateSlides.module.scss';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { TRANSITION_ICONS } from '../../../constants/transitions';

const TemplateSlides = ({
    activeSlide,
    selectSlide,
    setSlide,
    projectId,
    slides,
    updateIndexes,
    deleteSlide,
    infoNotification,
    authUser,
    getAvailableTemplates,
    clearAvailableTemplates,
    templates,
    categories,
    getCategories,
    projectCompanyName,
}) => {
    const isMounted = useRef(true);
    const [items, setItems] = useState([]);
    const prevSlidesLength = usePrevious(items.length);
    const [disabled, setDisabled] = useState(false);
    const [selectedTemplates, setSelectedTemplates] = useState(null);
    const [openAlert, setAlertOpen] = useState(false);
    const [actionClickDisable, setActionClickDisable] = useState(true);

    // Set items from slides
    useMount(async () => {
        try {
            await getAvailableTemplates(projectId);
            await getCategories({ companyNames: [projectCompanyName] });
        } catch (e) {
            console.info(e);
        }
    });

    useUnmount(() => {
        setItems([]);
        clearAvailableTemplates();
    });

    // Set items from slides
    useEffect(() => {
        isMounted.current = true;
        setItems(slides);
        // TODO check if we need this!
        // if (activeSlide && Object.keys(activeSlide).length) {
        //     // Use this ONLY for direct update of the same slide
        //     // when You don't need to update canvas inside "selectSlide" method
        //     if (slides[activeSlide.index] != null) {
        //     	console.log("slides[activeSlide.index] != null");
        //         setSlide(slides[activeSlide.index]);
        //     }
        // }
        if (activeSlide?.downloadUrl !== slides[activeSlide?.index]?.downloadUrl) {
            if (slides[activeSlide.index] != null) {
                setSlide(slides[activeSlide.index]);
            }
        }
        // We need to track slide changes for selectedTemplates duration calculations
        if (
            activeSlide?.canvasDataUpdatedAt !==
            slides[activeSlide?.index]?.canvasDataUpdatedAt
        ) {
            if (slides[activeSlide.index] != null) {
                // Call only for same slide because otherwise it will be triggered on reorder
                if (activeSlide.id === slides[activeSlide.index].id) {
                    selectSlide(slides[activeSlide.index]);
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setItems, setSlide, selectSlide, slides]);

    useEffect(() => {
        if (
            prevSlidesLength != null &&
            items.length > 0 &&
            prevSlidesLength < items.length &&
            items.length - prevSlidesLength === 1
        ) {
            // Update on slides add/restore and select last one
            // and avoid to do this on first render
            const lastIndex = Math.max(items.length - 1, 0);
            if (items[lastIndex]) {
                selectSlide(items[lastIndex]);
            }
        } else if (!prevSlidesLength && items.length > 0) {
            // Select first slide when slides appears initially
            // on first render
            selectSlide(items[0] || {});
        }
    }, [prevSlidesLength, items.length, items, selectSlide]);

    // Remove disabled flag only then new slides served as prop and update slides
    useEffect(() => {
        setDisabled(false);
    }, [slides.length]);

    function handleAlertOpen() {
        setAlertOpen(true);
    }

    function handleAlertClose() {
        setAlertOpen(false);
        setDisabled(false);
        setSelectedTemplates(null);
    }

    async function addSlide() {
        // Set disabled for add button to prevent same indexes creation
        setDisabled(true);
        setSelectedTemplates(null);
        // Get all templates
        await handleCategoryChange();
        handleAlertOpen();
    }

    function handleAlertAddTemplate() {
        if (selectedTemplates?.length) {
            const slide = {
                imageUrl: 'empty',
            };
            selectSlide(slide, selectedTemplates);
            handleAlertClose();
        }
    }

    function handleSelectTemplate(data) {
        setSelectedTemplates(data);
        setActionClickDisable(!data.length);
    }

    async function handleCategoryChange(data) {
        const query = data ? { category: data } : {};
        handleSelectTemplate([]);
        await getAvailableTemplates(projectId, query);
    }

    async function handleDelete(index) {
        // Set disabled for add button to prevent same indexes creation
        setDisabled(true);
        const message = `${locale.Messages.SLIDE_WAS_DELETED(slides[index].index + 1)}`;
        const data = {
            actionType: locale.NotificationActionTypes.SLIDE_UNDO,
            slideId: slides[index].id,
            projectId,
        };

        infoNotification(message, authUser, data);
        try {
            await deleteSlide(slides[index].id, projectId);
            const newSlides = [...slides];
            newSlides.splice(index, 1);
            if (newSlides[index]) {
                selectSlide(newSlides[index]);
            } else if (newSlides.length) {
                selectSlide(newSlides[newSlides.length - 1]);
            } else {
                selectSlide({});
            }
        } catch (e) {
            console.error(e);
        }
    }

    const reorder = (startIndex, endIndex, list) => {
        const result = Array.from(list);
        result[endIndex].index = startIndex;
        result[startIndex].index = endIndex;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    async function onDragEnd({ source, destination }) {
        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.index !== destination.index) {
            const startIndex = source.index;
            const endIndex = destination.index;
            const reorderedItems = reorder(startIndex, endIndex, items);
            setItems(reorderedItems);
            try {
                const updatedSlides = await updateIndexes(
                    startIndex,
                    endIndex,
                    projectId,
                );
                // if (activeSlide?.id !== updatedSlides[endIndex].id) {
                selectSlide(updatedSlides[endIndex]);
                // }
            } catch (e) {
                console.error(e);
            }
        }
    }

    return projectCompanyName ? (
        <div className={classes.SlidesLine}>
            <Btn
                className={classes.TimeLineBtn}
                text="add slide"
                onClick={addSlide}
                disabled={disabled}
                classes={{ label: classes.TimeLineBtnLabel }}
            >
                <AddIcon />
            </Btn>
            <Alert
                alertConfig={{
                    alertTitle: `Choose from ${projectCompanyName} templates`,
                    alertBtnText: 'Add',
                    paperClass: 'templatesPopup',
                    maxWidth: 'xs',
                    actionClick: handleAlertAddTemplate,
                    cancelAction: handleAlertClose,
                    actionClickDisable,
                }}
                open={openAlert}
            >
                {!!categories?.length && (
                    <CategoriesPicker
                        categories={categories}
                        onChange={handleCategoryChange}
                    />
                )}
                {templates && !!Object.keys(templates).length && (
                    <SelectTemplate
                        templates={templates}
                        selectTemplate={handleSelectTemplate}
                        text={locale.TEMPLATES}
                        selectedTemplates={selectedTemplates?.length}
                    />
                )}
                {(!templates || !Object.keys(templates).length) && (
                    <div className={classes.noData}>
                        <Typography paragraph>{locale.NO_DATA_AVAILABLE}</Typography>
                    </div>
                )}
            </Alert>
            {items && !!Object.keys(items).length && (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable-slides" direction="horizontal">
                        {provided => (
                            <div
                                className={classes.SlidesLineRow}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {items.map((slide, i) => {
                                    return (
                                        Object.keys(slide).length > 0 && (
                                            <Draggable
                                                key={slide.id}
                                                draggableId={slide.id}
                                                index={i}
                                            >
                                                {provided => (
                                                    <div
                                                        className={classes.TimeLineSlide}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div
                                                            className={
                                                                classes.SlideTransition
                                                            }
                                                        >
                                                            {slide.transitionName !==
                                                                'none' && (
                                                                <>
                                                                    <span>
                                                                        <ArrowRightAltIcon
                                                                            classes={{
                                                                                root:
                                                                                    classes.ArrowRoot,
                                                                            }}
                                                                        />
                                                                    </span>
                                                                    <span
                                                                        className={
                                                                            classes.transitionDuration
                                                                        }
                                                                    >
                                                                        {
                                                                            slide.transitionDuration
                                                                        }
                                                                        s
                                                                    </span>
                                                                    <span className="item">
                                                                        <img
                                                                            src={
                                                                                TRANSITION_ICONS[
                                                                                    slide
                                                                                        .transitionName
                                                                                ]
                                                                            }
                                                                            alt={
                                                                                slide.transitionName
                                                                            }
                                                                        />
                                                                    </span>
                                                                </>
                                                            )}
                                                        </div>
                                                        <Fab
                                                            size="small"
                                                            variant="extended"
                                                            disableFocusRipple
                                                            disabled={disabled}
                                                            onClick={() =>
                                                                handleDelete(i)
                                                            }
                                                            className={
                                                                classes.DeleteSlide
                                                            }
                                                            aria-label="Delete"
                                                        >
                                                            <DeleteIcon
                                                                fontSize="small"
                                                                htmlColor="#5C5C5C"
                                                            />
                                                        </Fab>

                                                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                                                        <span
                                                            role="button"
                                                            className={clsx(
                                                                classes.Outline,
                                                                'pointer',
                                                                slide?.id ===
                                                                    activeSlide?.id &&
                                                                    classes.active,
                                                            )}
                                                            tabIndex={i}
                                                            onClick={() =>
                                                                selectSlide(slide)
                                                            }
                                                        >
                                                            {slide &&
                                                                !!Object.keys(slide)
                                                                    .length && (
                                                                    <>
                                                                        <VideoDurationAlertIcon
                                                                            slide={slide}
                                                                        />
                                                                        <Slide
                                                                            index={i + 1}
                                                                            slide={slide}
                                                                            company={
                                                                                projectCompanyName
                                                                            }
                                                                            className={
                                                                                classes.Slide
                                                                            }
                                                                            noPointer
                                                                            smallSpinner
                                                                        />
                                                                    </>
                                                                )}
                                                        </span>
                                                    </div>
                                                )}
                                            </Draggable>
                                        )
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )}
        </div>
    ) : null;
};

TemplateSlides.propTypes = {
    projectId: string.isRequired,
    activeSlide: SlideObject,
    activeTemplate: object,
    slides: arrayOf(SlideObject),
    templates: arrayOf(Template),
    categories: arrayOf(Category),
    selectSlide: func.isRequired,
    updateIndexes: func.isRequired,
    deleteSlide: func.isRequired,
    getAvailableTemplates: func.isRequired,
    clearAvailableTemplates: func.isRequired,
    getCategories: func.isRequired,
    projectCompanyName: string,
};
export default TemplateSlides;
